import React, { Component } from 'react';

class StatusCard extends Component { 
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <div className={`card status-card ${this.props.className} ${this.props.checkedIcon === '' ? '-checked': ''}`}>
        {
          this.props.status === 'Inactivo' && 
          <div className="body">
            <>
              <p className="h4-b">Inactivo</p>
              <ul>
                <li>No se podrá enviar plantillas de WhatsApp</li>
              </ul>
            </>
          </div>
        }

        {
          this.props.status === 'Activo' && 
          <div className="body">
            <>
              <p className="h4-b">Activo</p>
              <ul>
                <li>Se podrá reactivar contacto por plantillas de WhatsApp</li>
              </ul>
            </>
          </div>
        }

        <div className="select">
          <button onClick={this.props.checkPermission} className="button -text p-0">
            <label className={`mb-0 ${this.props.checkedText}`} htmlFor="">Seleccionar</label>
            <span class={`material-icons-outlined md-48 ${this.props.checkedIcon}`}>check_circle_outline</span>
          </button>
        </div>
      </div>
    );
  }
}

export default StatusCard;