import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import SideNav from '../Components/Sidenav/Sidenav'
import NavBar from '../Components/NavBar/NavBar'
import ResponsiveNavBar from '../Components/ResponsiveNavBar/ResponsiveNavBar'
import PermissionsCard from '../Components/PermissionsCard/PermissionsCard';
import StatusCard from '../Components/StatusCard/StatusCard';
import Footer from '../Components/Footer/Footer';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL
const re_email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regex_phone = /[\d]{10}/;

class NewOptoutUser extends Component {

  constructor(props) {
    super(props);
    this.state = { 

      modal:false,

      enableButton:false,

      phone:'',
      validPhone:false,
      warningPhone:'',

      email:'',
      validEmail:false,
      warningEmail:'',

      ticket:'',
      validTicket:false,
      warningTicket:'',

      status:'inactivo',
      validStatus:true

    }
  }

  toggle = () => {
    document.getElementById("newWorkspace").click();
  }

  resetProps = () => {
    this.setState({

        modal:false,
        enableButton:false,

        phone:'',
        validPhone:false,
        warningPhone:'',

        email:'',
        validEmail:false,
        warningEmail:'',

        ticket:'',
        warningTicket:'',

        status:'inactivo',
        validStatus:true
    });
  }


  submitForm = () => {
    // let user_doc = []
    let temp = {}
    temp['agent_name'] = localStorage.getItem("user_name");
    temp['channel'] = "whatsapp";
    temp['outbound_status'] = this.state.status.toLowerCase();
    temp['date'] = new Date().toISOString();
    temp['me_phone'] = this.state.phone;
    temp['email'] = this.state.email;
    temp['folio'] = this.state.ticket;

    console.log(temp)
    
    axios({
      method: 'POST',
      url: API_URL+ '/Clip/Optout/form',
      data:temp
    })
    .then( (res) => {
      console.log(res);
      this.resetProps();
      document.getElementById("publishUser").click();
      this.setState({redirect:true})
      
    }).catch(error => {
      console.error(error)
      console.log(error)
      
    });
    console.log("Click");
  }

  handleChange = (e, type) => {

    let value = e.target.value;
    this.setState({ [e.target.name] : value })

    if( type === "email"){
      this.validEmail(e.target.value)
    }else if( type === "phone" ){
      this.validPhone(e.target.value)
    }

  }

  validEmail = (value) => {
    
    let checkButton = false;

    if( re_email.test(value) )
    {
      checkButton = true && this.state.validPhone && this.state.validStatus;
      this.setState({ warningEmail: "", validEmail:true, enableButton: checkButton })
    }else{
      this.setState({ warningEmail: "Correo no válido. Varificar captura", validEmail: false, enableButton: false })
    }
  }

  validPhone = (value) => {

    let checkButton = false;

    if( regex_phone.test( value ) )
    {
      checkButton = true && this.state.validEmail && this.state.validStatus;
      this.setState({ warningPhone: "", validPhone: true, enableButton: checkButton })
    }else{
      this.setState({ warningPhone: "Número no válido. Verificar captura", validPhone: false, enableButton: false })
    }

  }

  render() {
    return (
      <div className="container-fluid px-0 d-flex">
      {this.state.redirect &&
        <Redirect to={'/optout-form'}></Redirect>
      }
      {/* Publish campaign Modal */}
      <div class="modal fade " id="publishUser" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="h1-b" id="exampleModalLabel">Registrar usuario </h5>
                <button type="button" class="button btn-close" data-bs-dismiss="modal" aria-label="Close"><span class="material-icons-outlined md-24">close</span></button>
              </div>
              <div class="modal-body">
                <p className="body text-center">Estás a punto de registrar los siguientes datos</p>
                <ul>
                  <li >Email : <b>{this.state.email}</b> </li>
                  <li >Teléfono : <b>{this.state.phone}</b></li>
                  <li >Ticket : <b>{this.state.ticket}</b></li>
                  <li >Estatus : <b>{this.state.status}</b></li>
                </ul>


              </div>
              <div class="modal-footer">
                <button type="button" class="button -text -danger" data-bs-dismiss="modal">cancelar</button>
                <button type="button" class={!this.state.enableButton ? "button -text" : "button -primary"} disabled={!this.state.enableButton} onClick={this.submitForm}>Registrar usuario</button>
              </div>
            </div>
          </div>
        </div>
        {/* Publish campaign Modal */}

        {/* Can't public user */}
        <div class="modal fade " id="cantSend" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="h1-b" id="exampleModalLabel">Rechazado</h5>
                <button type="button" class="button btn-close" data-bs-dismiss="modal" aria-label="Close"><span class="material-icons-outlined md-24">close</span></button>
              </div>
              <div class="modal-body">
                
                
                 <p className="body text-center">Es necesario realizar una prueba exitosa antes de poder publicar la campaña, haga clic en <span className="f-bold">"PROBAR {'campaña'.toUpperCase()}"</span></p>
                
              </div>
              <div class="modal-footer">
                <button type="button" class="button -text" data-bs-dismiss="modal">continuar</button>
              </div>
            </div>
          </div>
        </div>
        {/* Can't public user */}

        <SideNav
          {...this.props}
          className="d-none d-md-flex"
        />
        <div className="container-fluid px-0">
          <div className="row mx-0 d-none d-md-block">
            <div className="col-12 px-0">
              <NavBar/>
            </div>
          </div>
          <div className="row mx-0 d-md-none">
            <div className="col-12 px-0">
              <ResponsiveNavBar/>
            </div>
          </div>
          <div className="row mx-0 min-h-page">
            <div className="col-12 px-0">
              <div className="container mt-50">
                <div className="row">
                  <div className="col-12">
                    <p className="h1-b">Formulario</p>
                    <p className="body-r">Todos los usuarios que se cargan en esta área no recibirán más mensajes de tus campañas</p>
                  </div>
                </div>

                <div className="row mt-50">
                  <div className="col-12">
                    <div className="card">
                      <div className="row">
                        <div className="col-12">
                          <p className="h3-b mb-20">Información del usuario</p>
                        </div>
                      </div>

                      <div className="row">
                        {/* Email */}
                        <div className="col-12 col-md-4">
                          <div className={"input-group "+ (this.state.warningEmail ? '-error' : '')}>
                            <input 
                                type="email" 
                                name='email' 
                                onChange={ async (e) => {
                                    e.target.value = e.target.value.replace(/ñ|Ñ/g,"");
                                    this.handleChange( e, "email" );
                                }}
                                value={this.state.email}
                            />
                            <label className="inputname">Correo electrónico (obligatorio)</label>
                            {this.state.warningEmail && <label className="errormesage">{this.state.warningEmail}</label>}
                          </div>
                        </div>

                        {/* ME Phone */}
                        <div className="col-12 col-md-4 mb-20">
                          <div className={"input-group "+ (this.state.warningPhone ? '-error' : '')}>
                            <input 
                                type="text" 
                                name='phone' 
                                onChange={ async (e) => {
                                    e.target.value = e.target.value.replace(/[^\d]/gi, '');
                                    this.handleChange( e, "phone" );
                                }}
                                maxLength="10"
                                value={this.state.phone}
                            />
                            <label className="inputname">Número Celular (obligatorio)</label>
                            {this.state.warningPhone && <label className="errormesage">{this.state.warningPhone}</label>}
                          </div>
                        </div>

                        {/* Ticket */}
                        <div className="col-12 col-md-4 mb-20">
                          <div className={"input-group "+ (this.state.warningTicket ? '-error' : '')}>
                            <input 
                                type="text" 
                                name='ticket' 
                                onChange={ async (e) => {
                                  e.target.value = e.target.value.replace(/[^\d]/gi, '');
                                  this.handleChange( e, 'ticket');
                                } }
                                maxLength="15"
                                value={this.state.ticket}
                            />
                            <label className="inputname">Número Ticket</label>
                            {this.state.warningTicket && <label className="errormesage">{this.state.warningTicket}</label>}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <p className="h3-b mb-0 mt-30">Estatus</p>
                          <p className="body mb-0">Selecciona el estatus en el cuál se va a registrar</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 col-lg-6 d-flex">
                          <div className='template-info mt-20 w-100'>
                            <div className="iconwrapper">
                              <span class="material-icons-outlined md-36 c-primary-100">notifications_off</span>
                            </div> 
                            <div className="bodycontent">
                              <p className="h4-b mb-0">Inactivo</p>
                              <p className="body-r mb-0">Este usuario no recibirá plantillas de WhatsApp</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-lg-6 d-flex">
                          <div className='template-info mt-20 w-100'>
                            <div className="iconwrapper">
                              <span class="material-icons-outlined md-36 c-primary-100">notifications_active</span>
                            </div> 
                            <div className="bodycontent">
                              <p className="h4-b mb-0">Activo</p>
                              <p className="body-r mb-0">Este usuario podrá recibir plantillas de WhatspApp</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-20">
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="input-group">
                            <select name="status" id="" placeholder="Selecciona un estatus" onChange={this.handleChange} value={this.state.status}>
                              <option value="inactivo">Inactivo</option>
                              <option value="activo">Activo</option>
                            </select>
                            <label className="inputname">Selecciona un estatus:</label>
                            <label htmlFor="" className="errormesage">Debes seleccionar una opción</label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 col-md-6 col-lg-4 mt-30">
                          <button className="button -primary w-100" class={!this.state.enableButton ? "button -secondary w-100" : "button -primary w-100"}  disabled={!this.state.enableButton} data-bs-toggle="modal" data-bs-target={this.state.enableButton ? "#publishUser":"#cantSend"}>Registrar usuario</button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mx-0">
            <div className="col-12 px-0">
              <Footer
                className="mt-100"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewOptoutUser;