import React, { Component } from 'react';

class TemplateInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }

  render() {
    return (
      <div className={`template-info ${this.props.className}`}>
        <div className="iconwrapper">
          <span class="material-icons-outlined md-36 c-primary-100">warning_amber</span>
        </div> 
        <div className="bodycontent">
          <p className="h4-b mb-0">Campos necesarios de la plantilla</p>
          <p className="body-r">
            Máximo de 1,000 registros por campaña.
            De acuerdo a la plantilla seleccionada, el archivo debe contener los siguientes campos:
          </p>
          <div className="row">
            <div className="col-12 col-md-4">
              <p className="body-r d-flex align-itmes-center mb-0"><span class="material-icons-outlined c-primary fs-18">chevron_right</span> Contact Number</p>
            </div>
            {
              this.props.body > 0 && 
              <div className="col-12 col-md-4">
                <p className="body-r d-flex align-itmes-center mb-0"><span class="material-icons-outlined c-primary fs-18">chevron_right</span> Body 1</p>
              </div>
            }
            {
              this.props.body > 1 && 
              <div className="col-12 col-md-4">
                <p className="body-r d-flex align-itmes-center mb-0"><span class="material-icons-outlined c-primary fs-18">chevron_right</span> Body 2</p>
              </div>
            }
            {
              this.props.body > 2 && 
              <div className="col-12 col-md-4">
                <p className="body-r d-flex align-itmes-center mb-0"><span class="material-icons-outlined c-primary fs-18">chevron_right</span> Body 3</p>
              </div>
            }
            {
              this.props.body > 3 && 
              <div className="col-12 col-md-4">
                <p className="body-r d-flex align-itmes-center mb-0"><span class="material-icons-outlined c-primary fs-18">chevron_right</span> Body 4</p>
              </div>
            }
            {
              this.props.body > 4 && 
              <div className="col-12 col-md-4">
                <p className="body-r d-flex align-itmes-center mb-0"><span class="material-icons-outlined c-primary fs-18">chevron_right</span> Body 5</p>
              </div>
            }
            {
              this.props.body > 5 && 
              <div className="col-12 col-md-4">
                <p className="body-r d-flex align-itmes-center mb-0"><span class="material-icons-outlined c-primary fs-18">chevron_right</span> Body 6</p>
              </div>
            }
            {
              this.props.body > 6 && 
              <div className="col-12 col-md-4">
                <p className="body-r d-flex align-itmes-center mb-0"><span class="material-icons-outlined c-primary fs-18">chevron_right</span> Body 7</p>
              </div>
            }
            {
              this.props.body > 7 && 
              <div className="col-12 col-md-4">
                <p className="body-r d-flex align-itmes-center mb-0"><span class="material-icons-outlined c-primary fs-18">chevron_right</span> Body 8</p>
              </div>
            }
            {
              this.props.body > 8 && 
              <div className="col-12 col-md-4">
                <p className="body-r d-flex align-itmes-center mb-0"><span class="material-icons-outlined c-primary fs-18">chevron_right</span> Body 9</p>
              </div>
            }
            {
              this.props.body > 9 && 
              <div className="col-12 col-md-4">
                <p className="body-r d-flex align-itmes-center mb-0"><span class="material-icons-outlined c-primary fs-18">chevron_right</span> Body 10</p>
              </div>
            }
            {
              this.props.body > 10 && 
              <div className="col-12 col-md-4">
                <p className="body-r d-flex align-itmes-center mb-0"><span class="material-icons-outlined c-primary fs-18">chevron_right</span> Body 11</p>
              </div>
            }
            {
              this.props.url === 'true' && 
              <div className="col-12 col-md-4">
                <p className="body-r d-flex align-itmes-center mb-0"><span class="material-icons-outlined c-primary fs-18">chevron_right</span> URL de Header</p>
              </div>
            }
            {
              this.props.document === 'true' && 
            <div className="col-12 col-md-4">
              <p className="body-r d-flex align-itmes-center mb-0"><span class="material-icons-outlined c-primary fs-18">chevron_right</span> Nombre del documento</p>
            </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default TemplateInfo;