import React, { Component } from 'react';
import SideNav from '../Components/Sidenav/Sidenav'
import NavBar from '../Components/NavBar/NavBar'
import ResponsiveNavBar from '../Components/ResponsiveNavBar/ResponsiveNavBar'
import Footer from '../Components/Footer/Footer'
import EmptyPage from '../Components/EmptyPage/EmptyPage'
import TemplateInfo from '../Components/TemplateInfo/TemplateInfo';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import ImgSucces from '../img/success_post.svg';
import Lottie from 'react-lottie';
import animationData from '../img/lottie_files/Analyze.json';
import animationData2 from '../img/lottie_files/Loading.json'
import { Redirect } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const API_URL = process.env.REACT_APP_API_URL
const EXCEL_URL = process.env.REACT_APP_EXCEL_URL
const SENDERS_ID = process.env.REACT_APP_SENDERS_ID
const SENDER_NUMBER = process.env.REACT_APP_SENDER_NUMBER
const ACCESS_TOKEN = process.env.REACT_APP_ACCESS_TOKEN
const storage_url='https://storage.cloud.google.com/afore_excel_files'
const storage_auth=process.env.REACT_STORAGE_BASE_URI
const version = process.env.REACT_APP_VERSION
const workspace_ch_value = process.env.REACT_APP_WORKSPACE_CH_VALUE.replaceAll("_", " ")
const workspace_ce_value = process.env.REACT_APP_WORKSPACE_CE_VALUE.replaceAll("_", " ")
const workspace_clip_ob = process.env.REACT_APP_WORKSPACE_CLIP_VALUE.replaceAll("_", " ")
const workspace_clip_hunters = process.env.REACT_APP_WORKSPACE_HUNTERS_NOTIFICATION.replaceAll("_", " ")
const workspace_clip_hunters_agents = process.env.REACT_APP_WORKSPACE_HUNTERS_AGENTS.replaceAll("_", " ")
const workspace_clip_emanaged = process.env.REACT_APP_WORKSPACE_EMANAGED.replaceAll("_", " ")
const workspace_clip_emanaged_mob = process.env.REACT_APP_WORKSPACE_EMANAGED_MOB.replaceAll("_", " ")
const workspace_clip_emanaged_mg = process.env.REACT_APP_WORKSPACE_EMANAGED_MG.replaceAll("_", " ")
const workspace_clip_emanaged_mg_backup = process.env.REACT_APP_WORKSPACE_EMANAGED_MG_BACKUP.replaceAll("_", " ")
const workspace_clip_mm = process.env.REACT_APP_WORKSPACE_CH_MM.replaceAll("_", " ")

const options = [
  { value: 'lunes', label: 'Lunes' },
  { value: 'martes', label: 'Martes' },
  { value: 'miércoles', label: 'Miércoles' },
  { value: 'jueves', label: 'Jueves' },
  { value: 'viernes', label: 'Viernes' },
  { value: 'sábado', label: 'Sábado' },
  { value: 'domingo', label: 'Domingo' },
];

const animatedComponents = makeAnimated();

class NewCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {  
      //phone_options:[SENDER_NUMBER],
      origin_phones_list :[],
      workspace_templates_ch : [],
      workspace_templates_ce : [],
      workspace_templates_clip: [],
      workspace_templates_an: [],
      workspace_templantes_emanaged: [],
      workspace_templates_emanaged_mob: [],
      workspace_templates_emanaged_mg: [],
      workspace_templates_mm: [],
      template_options : [],
      test_options:[],
      modal:false,
      excel_modal:false,
      name:'',
      template:'',
      destinataries:'',
      loading:false,
      campaign_names:[],
      invalid_name:false,
      tested:false,
      outbound_messages:[],
      analyzed:false,
      date:'',
      hour:'',
      disable_button:false,
      schedule_campaign:false
    }
  }

  componentDidMount = () => {
     

    let phones_options = [{
      "value":"",
      "phone":""
    }]

    let workspace_ch = [];
    let workspace_ce = [];
    let workspace_clip = [];
    let workspace_an = [];
    let workspace_emanaged = [];
    let workspace_emanaged_mob = [];
    let workspace_emanaged_mg = [];
    let workspace_mm = [];

    let origin_phone_number = JSON.parse(localStorage.getItem("origin_phones"));
    let string_phone_array = []
    origin_phone_number.forEach(element => {
      string_phone_array.push(element.phone);
    });

    let user_phones = JSON.parse(localStorage.getItem("origin_phones"))
    
    user_phones.forEach(element => {
      phones_options.push(element);
    });

    this.setState({
      origin_phones_list: phones_options
    })

    axios({
      method: 'GET',
      url: `${API_URL}/testPhones?user_id=${jwtDecode(localStorage.getItem("id_token")).sub.replace('auth0|','')}`
    })
    .then( (res) => {
      // let template_options = [{template_name:'Selecciona un template'}].concat(res.data.response)
      this.setState({test_options:res.data.phone_numbers})
      if(res.data.phone_numbers.length == 1) this.setState({test_phone:res.data.phone_numbers[0]})

      // this.setState({test_options: ["5516894714", "5539518126", "5579184062"]})
    }).catch(error => {
      this.setState({test_options: ["5516894714", "5539518126", "5579184062"]})
      console.error('Error found in:', (error));
    });    
    if(user_phones.length == 1) this.setState({destinataries:user_phones[0].phone})
    axios({
      method: 'GET',
      url: API_URL + '/templates/outbound/sunshine?template_case=new_campaign',
      headers: {"X-Api-Key": ACCESS_TOKEN}
    })
    .then( (res) => {
      // let template_options = [{template_name:'Selecciona un template'}].concat(res.data.response)
      
      console.log(res.data)

      let templates = res.data.response;

      templates.forEach(element => {
        
        if( element.workspace_group === "CHWhatsAppOB" ){
          workspace_ch.push(element);
        }else if(element.workspace_group === "WAClipEmpresasOB"){
          workspace_ce.push(element);
        }else if(element.workspace_group === "ClipWhatsAppOB" || element.workspace_group === "WHSupportAndBeyond" || element.workspace_group === "LogisticShipping"){
          workspace_clip.push(element);
        }else if(element.workspace_group === "WANotificationAgent" ){
          workspace_an.push(element);
        }else if(element.workspace_group === "WAEmanagedOB"){
          workspace_emanaged.push(element);
        }else if( element.workspace_group === "WA_Emanaged_MOB"){
          workspace_emanaged_mob.push(element);
        }else if( element.workspace_group === "WA_Emanaged_MG")
        {
          workspace_emanaged_mg.push(element);
        }else if( element.workspace_group == "WAMM" ){
          workspace_mm.push(element);
        }
        else{
          workspace_ch.push(element);
        }

      });


      this.setState({
        workspace_templates_ch : workspace_ch,
        workspace_templates_ce : workspace_ce,
        workspace_templates_clip: workspace_clip,
        workspace_templates_an : workspace_an,
        workspace_templantes_emanaged : workspace_emanaged,
        workspace_templates_emanaged_mob : workspace_emanaged_mob,
        workspace_templates_emanaged_mg : workspace_emanaged_mg,
        workspace_templates_mm : workspace_mm

      })
    }).catch(error => {
      console.error('Error found in:', (error));
    });   
    let user_id = ''
    if(this.props.auth.isAuthenticated()) user_id = jwtDecode(localStorage.getItem("id_token")).sub.replace('auth0|','')
    axios({
      method: 'GET',
      url: `${API_URL}/clip/campaigns/outbound?origin_phone=${string_phone_array.join("-")}`,
      headers: {"X-Api-Key": ACCESS_TOKEN}
      //url: `${API_URL}/campaigns?user_id=${user_id}`
    })
    .then( (res) => {
      let users = []
      let valid_campaigns = []
      let c = res.data.response
      console.log("response= ",c)
      for (let index = 0; index < c.length; index++) {
        if(users.indexOf(c[index]._id) == -1){
          users.push(c[index]._id)
        }
        valid_campaigns.push(c[index]._id)      
      }
      this.setState({campaign_names:valid_campaigns})
    }).catch(error => {
      console.error('Error found in:', (error));
    });  
  }

  calculate_date = (date, schedule) => {
    var newDate = date
    if(this.state.date){
      var sMonth = this.state.date.getMonth() + 1
      var sDay = this.state.date.getDate()
      var sYear = this.state.date.getFullYear()
    }else{
      var sMonth = newDate.getMonth() + 1
      var sDay = newDate.getDate()
      var sYear = newDate.getFullYear()
    }
    var sHour = newDate.getHours()
    // if ( schedule === "false" ) sHour -= 1
    var sMinute = newDate.getMinutes()
    var sSecs = newDate.getSeconds()
    var sAMPM = "AM"

    var iHourCheck = parseInt(sHour)

    if (iHourCheck > 12) {
      sAMPM = "PM"
      sHour = iHourCheck - 12
    }
    else if (iHourCheck === 0) {
      sHour = "12";
    }else if (iHourCheck==12){
      sAMPM="PM";
    }

    sHour = sHour;

    return sMonth + "/" + sDay + "/" + sYear + " " + sHour + ":" + sMinute + ":" +sSecs + " "+ sAMPM;
  }

  setDate = (date) => {
    this.setState({date}) 
  }

  setTime = (time) => {
    this.setState({time})
  }

  validate_name = e => {
    this.setState({invalid_name:(this.state.campaign_names.indexOf(e.target.value) != -1)})
  }

  onDateChange = d => {
    this.setState({date:d})
  }

  onHourChange = d => {
    this.setState({hour:d})
  }


  onChange = e => {
    let value = e.target.value
    if(e.target.name=="name") value = value.toUpperCase()
    this.setState({ [e.target.name]: value })
    let templates_by_workspace = []
    if( e.target.name === "destinataries" ){
      let filter_phones = this.state.origin_phones_list.filter(element => element.phone === value);
      if( workspace_ch_value === filter_phones[0].value && filter_phones.length === 1 )
      {
        templates_by_workspace = this.state.workspace_templates_ch
      }else if ( workspace_ce_value === filter_phones[0].value && filter_phones.length === 1 ){
        templates_by_workspace = this.state.workspace_templates_ce
      }else if ( workspace_clip_ob === filter_phones[0].value && filter_phones.length === 1 ){
        templates_by_workspace = this.state.workspace_templates_clip
      }else if ( (workspace_clip_hunters === filter_phones[0].value || workspace_clip_hunters_agents === filter_phones[0].value) && filter_phones.length === 1 ){
        templates_by_workspace = this.state.workspace_templates_an
      }else if ( workspace_clip_emanaged === filter_phones[0].value && filter_phones.length === 1 ){
        templates_by_workspace = this.state.workspace_templantes_emanaged
      }else if ( workspace_clip_emanaged_mob === filter_phones[0].value && filter_phones.length === 1){
        templates_by_workspace = this.state.workspace_templates_emanaged_mob
      }else if ( ( workspace_clip_emanaged_mg === filter_phones[0].value || workspace_clip_emanaged_mg_backup === filter_phones[0].value ) && filter_phones.length === 1){
        templates_by_workspace = this.state.workspace_templates_emanaged_mg
      }else if ( workspace_clip_mm === filter_phones[0].value && filter_phones.length === 1){
        templates_by_workspace = this.state.workspace_templates_mm
      }
      else{
        if ( filter_phones.length >= 1 ){
          templates_by_workspace = templates_by_workspace.concat(this.state.workspace_templates_ce, this.state.workspace_templates_ch)
        }
        console.log("Error")
        console.log(filter_phones[0].value)
      }

      templates_by_workspace = templates_by_workspace.sort((a, b) => (a.template_name > b.template_name) ? 1 : -1)

      this.setState({
        template_options: templates_by_workspace
      })
    }
  }


  onChangeTemplate = e => this.setState({[e.target.name]: this.state.template_options[e.target.value]})
  loadExcel = (event) => {
    let file;
    let xlsx;
    this.setState({outbound_messages:[],file_key:'', tested:false, analyzed:false})
    try {
      file = event.target.files[0];
    } catch {
      file = event
    }
    if (file) {
      xlsx = file.type === 'application/xlsx';
      this.setState({
        image_event: file,
        display_form: ''
      })
      if (false) {
        // alert('Por favor suba una imagen con dimensiones mayores a 120 x 120')
        this.setState({
          modal: !this.state.modal,
          active: this.state.comp_id
        })
      }
      else {
        this.reader = new FileReader();
        this.reader.readAsDataURL(file)
        this.reader.onloadend = () => {
          let document = new FormData()
          document.append('doc',file)
          document.append('file',this.reader.result)
          
          this.setState({
            doc:document
          })
        }
      }
    }
  }

  readyAnalyze = () => {
    let template = this.state.template!=''
    let dest = this.state.destinataries!=''
    let name = this.state.name!=''
    return (template && dest && name && !this.state.invalid_name)
  }

  canPublish = () => {
    return this.state.tested && this.state.outbound_messages.length > 0
  }

  send_bulk = () => {
    this.setState({disable_button: true})
    this.setState({loading: true})
    let messages = this.state.outbound_messages

    var schedule_campaign = false;

    if(this.state.date && this.state.hour){
      schedule_campaign = true;
      console.log("dia de envío:", this.state.date)
      console.log("hora de envío:", this.state.hour)
      let start_date = this.calculate_date(this.state.hour, 'true')
      console.log("fecha de envío de publicación de campaña:", start_date)
      messages.forEach(message =>{
        message['sendAt'] = start_date
      })
    }
    else{
      schedule_campaign = false;
      messages.forEach(message =>{
        let start_date= this.calculate_date(new Date(), 'false')
        message['sendAt'] = start_date
      })
    }
    messages.forEach(message =>{
      if(message.template_params.header.documentUrl === "nan"){
        delete message.template_params["header"]
      }else{
        message.template_params.header["filename"] = message.template_params.header.documentFilename 
        message.template_params.header["link"] = message.template_params.header.documentUrl
        message.template_params.header["type"] = this.state.template.header === undefined ? "image": this.state.template.header.type.toLowerCase();
        delete message.template_params.header["documentFilename"]
        delete message.template_params.header["documentUrl"]
      }

      if( message.payload !== "nan")
      {
        message.template_params["payloads"] = this.state.template.payload;
      }

    })
    console.log(messages)
    let clip_phone = this.state.destinataries + ""
    
    axios({
      method: 'POST',
      url: API_URL + '/message/whatsapp/sendOutbound',
      data:{
        messages:messages,
        registry_error:this.state.errors + 1,
        test:false,
        user_id: localStorage.getItem("user_name"),
        reply_type : this.state.template.reply_type,
        requestedBy: "WebService",
        origin_phone: clip_phone,
        schedule_campaign: schedule_campaign
      },
      headers: { 
        'X-Api-Key': ACCESS_TOKEN,
        'Content-Type': 'application/json' 
      }
    })
    .then( (res) => {
      console.log(res.data);
      document.getElementById("publishCampaign").click();
      this.setState({loading: false});
      this.setState({redirect:true, analyzed:true});
      this.setState( { schedule_campaign: schedule_campaign } );
      
    }).catch(error => {
      console.log(error)
      document.getElementById("publishCampaign").click();
      this.setState({loading: false});
      this.setState({redirect:true, analyzed:true});
    });
  }

  valid_campaign = () => {
    let name_validation = this.state.name && this.state.invalid_name
    return name_validation && this.state.template && this.state.outbound_messages.length > 0 && this.state.destinataries
  }

  sendTest=()=>{
    let messages = this.state.outbound_messages;
    let test_message = JSON.parse(JSON.stringify(messages[0])) ;
    test_message.client_phone_number = this.state.test_phone;
    test_message.from_phone_number = this.state.destinataries + "";
    test_message.sendAt=this.calculate_date(new Date(), 'false');
    
    if(test_message.template_params.header.documentUrl === "nan"){
      delete test_message.template_params["header"]
    }else{
      test_message.template_params.header["filename"] = test_message.template_params.header.documentFilename 
      test_message.template_params.header["link"] = test_message.template_params.header.documentUrl 
      test_message.template_params.header["type"] = this.state.template.header.type.toLowerCase();
      delete test_message.template_params.header["documentFilename"]
      delete test_message.template_params.header["documentUrl"]
    }
    console.log(test_message);
    if( test_message.payload !== "nan")
    {
      test_message.template_params["payloads"] = this.state.template.payload;
    }
    
    document.getElementById("testCampaign").click();
    document.getElementById("sending").click();
    axios({
      method: 'POST',
      url: API_URL + '/message/whatsapp/sendOutbound',
      data:{
        messages:[test_message],
        registry_error:this.state.errors,
        test:true,
        user_id:jwtDecode(localStorage.getItem("id_token")).sub.replace('auth0|',''),
        reply_type : this.state.template.reply_type,
        requestedBy: "Cloud"
      },
      headers: { 'X-Api-Key': ACCESS_TOKEN }
    })
    .then( (res) => {
      console.log('RESPONSE-->',res.data)
      this.setState({tested:true})
      document.getElementById("sending").click();
    })
  }

  reset_doc = () => {
    this.setState({doc:'', tested:false, analyzed:false, outbound_messages:[], correct:0, errors:0, file_key:""},()=>{
      document.getElementById("file-upload").value=''
    })
  }

  analyze=()=>{
    if(this.readyAnalyze()){
      this.setState({loading:true,excel_error:false})
      document.getElementById("loading_display").click();
      let doc =  this.state.doc
      doc.delete('documentName')
      doc.delete('documentUrl')
      doc.delete('template_name')
      doc.delete('body_size')
      doc.delete('destinatary')
      doc.delete('campaign')

      doc.append('template_name',this.state.template.template_name)
      doc.append('body_size',this.state.template.body_params)
      doc.append('destinatary', this.state.destinataries)
      doc.append('campaign', this.state.name)
      if(this.state.template.header){
        doc.append('documentName`', this.state.template.header.documentName)
        doc.append('documentUrl', this.state.template.header.documentUrl)
      }
      axios({
        method: 'POST',
        url: EXCEL_URL,
        data:doc,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      .then( (res) => {
        this.setState({
          file_key:`${storage_url}/${res.data.key}?authuser=0`, 
          outbound_messages:res.data.success,
          total:res.data.length + 1,
          correct: res.data.success.length,
          errors:res.data.errors,
          loading:false,
          analyzed:true
        })
        // if(res.data.error_msg.length > 0) document.getElementById("error_display").click();
      }).catch(error => {
        console.error(error)
        console.log(error)
        this.reset_doc()
        this.setState({loading:false, excel_error:true})
      });
    }
  }

  render() {
    const analyze = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      }
    }
    const loading = {
      loop: true,
      autoplay: true,
      animationData: animationData2 ,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      }
    }

    //set the max day that can be selected
    const datemax= new Date();
    const maxdate=datemax.setDate(datemax.getDate()+45) ;

    return (
      <div className="container-fluid px-0 d-flex">
        {this.state.redirect &&
          <Redirect to={'/seller-admin'}></Redirect>
        }
        {/* Publish campaign Modal */}
        <div class="modal fade " id="publishCampaign" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              {
                !this.state.loading &&
                <div class="modal-header">
                  <h5 class="h1-b" id="exampleModalLabel">Publicar campaña </h5>
                  <button type="button" class="button btn-close" data-bs-dismiss="modal" aria-label="Close"><span class="material-icons-outlined md-24">close</span></button>
                </div>
              }
              {
                this.state.loading &&
                  <div className='modal-header'>
                  <h5 class="h1-b" id="exampleModalLabel">Publicando campaña </h5>
                </div>
              }
              
              
              <div class="modal-body" className="container">
                {
                  this.state.loading &&
                  <Lottie
                    options={loading}
                    width={500}
                    height={500}
                  />
                }
                {
                  !this.state.loading  &&
                  <p className="body text-center">Estás a punto de  publicar esta campaña, este mensaje llegará a todos los contactos agregados</p>
                
                }
              </div>
              
              {
                !this.state.loading &&
                <div class="modal-footer">
                  <button type="button" class="button -text" data-bs-dismiss="modal">cancelar</button>
                  <button type="button" class={this.state.disable_button ? "button -text" : "button -primary"} disabled={this.state.disable_button} onClick={this.send_bulk}>Publicar campaña</button>
                </div>
              }
                
              
            </div>
          </div>
        </div>
        {/* Publish campaign Modal */}

        {/* Send Campaign test modal */}
        <div class="modal fade " id="testCampaign" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="h1-b" id="exampleModalLabel">Mandar prueba</h5>
                <button type="button" class="button btn-close" data-bs-dismiss="modal" aria-label="Close"><span class="material-icons-outlined md-24">close</span></button>
              </div>
              <div class="modal-body">
                <p className="body text-center">Mandaremos una prueba al teléfono que seleccionaste para pruebas</p>  
              </div>
              <div class="modal-footer">
                <button type="button" class="button -text" data-bs-dismiss="modal">cancelar</button>
                <button type="button" class="button -primary" onClick={this.sendTest}>Mandar prueba</button>
              </div>
            </div>
          </div>
        </div>

        {/* Send Campaign test modal */}
        <div class="modal fade " id="cantSend" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="h1-b" id="exampleModalLabel">Rechazado</h5>
                <button type="button" class="button btn-close" data-bs-dismiss="modal" aria-label="Close"><span class="material-icons-outlined md-24">close</span></button>
              </div>
              <div class="modal-body">
                <Lottie
                    options={analyze}
                    width={280}
                  />
                {this.state.outbound_messages.length > 0 && !this.state.tested && this.state.analyzed &&
                 <p className="body text-center">Es necesario realizar una prueba exitosa antes de poder publicar la campaña, haga clic en <span className="f-bold">"PROBAR {'campaña'.toUpperCase()}"</span></p>
                }
                {this.state.outbound_messages.length == 0 &&  this.state.analyzed &&
                 <p className="body text-center">No se encontraron registros exitosos para publicar la campaña, revise el archivo de errores</p>
                }
                {!this.state.analyzed &&  
                 <p className="body text-center">Es necesario Analizar el archivo antes de continuar con la campaña, por favor de click en el botón <span className="f-bold">"ANALIZAR ARCHIVO"</span></p>
                }
              </div>
              <div class="modal-footer">
                <button type="button" class="button -text" data-bs-dismiss="modal">continuar</button>
              </div>
            </div>
          </div>
        </div>
        {/* Send Campaign test modal */}

        {/* analyzing file... */}
        <div class="modal fade " id="loading" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="h1-b" id="exampleModalLabel">{this.state.loading ? 'Cargando': this.state.excel_error ? 'Error':'Completado'}</h5>
                {
                  !this.state.loading &&
                <button type="button" class="button btn-close" data-bs-dismiss="modal" aria-label="Close"><span class="material-icons-outlined md-24">close</span></button>
                }
              </div>
              <div class="modal-body">
                {
                  this.state.loading &&
                  <Lottie
                      options={analyze}
                      width={280}
                    />
                }
                  {
                    !this.state.loading && !this.state.excel_error &&
                  <p className="body text-center"><span className="f-bold">{this.state.correct}/{this.state.total}</span> de los registros fueron aceptados</p>
                  }
                  {
                    !this.state.loading && this.state.excel_error &&
                    <p className="body text-center"><span className="f-bold"></span> Ocurrió un error al intentar analizar el archivo, revise que el documento siga el formato adecuado e intente otra vez. Si el documento se modificó después de subirlo, vuelva a subir</p>
                  }
              </div>
              {
                !this.state.loading &&
              <div class="modal-footer">
                <button type="button" class="button -text" data-bs-dismiss="modal">continuar</button>
              </div>
              }
              {this.state.file_key && !this.state.loading && this.state.correct!=this.state.total &&
                <div class="modal-footer">
                  <a href={this.state.file_key}>
                      Descargar Archivo de Errores
                  </a>
                </div>
              }
            </div>
          </div>
        </div>
        {/* analyzing file... */}
        <div class="modal fade " id="sending" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="h1-b" id="exampleModalLabel">Mandando Mensajes</h5>
              </div>
              <div class="modal-body">                
                <Lottie
                    options={analyze}
                    width={280}
                  />
              </div>
              
            </div>
          </div>
        </div>
        {/* analyzing file... */}

        {/* Empty fields modal */}
        <div class="modal fade " id="emptyFields" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="h1-b" id="exampleModalLabel"><span class="material-icons error">cancel</span></h5>
                <button type="button" class="button btn-close" data-bs-dismiss="modal" aria-label="Close"><span class="material-icons-outlined md-24">close</span></button>
              </div>
              <div class="modal-body">
                <p className="body text-center">Todos los campos requeridos deben llenarse.</p>
                <p className="body text-center">Marcamos todos los campos que faltan para que pueda identificarlo fácilmente.</p>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <button type="button" class="button -primary" data-bs-dismiss="modal">Continuar</button>
              </div>
            </div>
          </div>
        </div>
        {/* Empty fields modal */}
        <div class="modal fade " id="fileChanged" tabindex="-1" aria-labelledby="fileChanged" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="h1-b" id="exampleModalLabel"><span class="material-icons error">cancel</span></h5>
                <button type="button" class="button btn-close" data-bs-dismiss="modal" aria-label="Close"><span class="material-icons-outlined md-24">close</span></button>
              </div>
              <div class="modal-body">
                <p className="body text-center">El archivo fue modificado</p>
                <p className="body text-center">Marcamos todos los campos que faltan para que pueda identificarlo fácilmente.</p>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <button type="button" class="button -primary" data-bs-dismiss="modal">Continuar</button>
              </div>
            </div>
          </div>
        </div>
        {/* Missing data to analyze document */}
        <div class="modal fade " id="analyze" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="h1-b" id="exampleModalLabel">Rechazado</h5>
                <button type="button" class="button btn-close" data-bs-dismiss="modal" aria-label="Close"><span class="material-icons-outlined md-24">close</span></button>
              </div>
              <Lottie
                    options={analyze}
                    width={280}
                  />
              
              <div class="modal-body">
              <p className="body text-center">Se tiene que seleccionar un número telefónico, una plantilla, y un nombre de campaña válido antes de poder realizar el análisis del documento.</p>
                <p className="body text-center">Marcamos todos los campos que faltan para que puedas identificarlos fácilmente.</p>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <button type="button" class="button -primary" data-bs-dismiss="modal">Continuar</button>
              </div>
            </div>
          </div>
        </div>
        {/* Display erros */}
        <div class="modal fade " id="errors" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="h1-b" id="exampleModalLabel"><span class="material-icons error">cancel</span></h5>
                <button type="button" class="button btn-close" data-bs-dismiss="modal" aria-label="Close"><span class="material-icons-outlined md-24">close</span></button>
              </div>
              <div class="modal-body">
                {this.state.error_msg && this.state.error_msg.length > 0 && this.state.error_msg.map((error)=>{
                  return(
                    <p className="body text-center">{error}</p>
                  )
                })}
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <button type="button" class="button -primary" data-bs-dismiss="modal">Continuar</button>
              </div>
            </div>
          </div>
        </div>
        {/* Empty fields modal */}

        {/* Success modal */}
        <div class="modal fade " id="success" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="h1-b" id="exampleModalLabel">Campaña publicada</h5>
                <button type="button" class="button btn-close" data-bs-dismiss="modal" aria-label="Close"><span class="material-icons-outlined md-24">close</span></button>
              </div>
              <div class="modal-body d-flex flex-column">
                <p className="body text-center">Su campaña ha sido publicada con éxito.</p>
                <img className="mx-auto" src={ImgSucces} alt="imagén de un teléfono" />
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <button type="button" class="button -primary" data-bs-dismiss="modal">Continuar</button>
              </div>
            </div>
          </div>
        </div>
        {/* Success modal */}

        <SideNav
          className="d-none d-md-flex"
          {...this.props}
        />
        <div className="container-fluid px-0">
          <div className="row mx-0 d-none d-md-block">
            <div className="col-12 px-0">
              <NavBar/>
            </div>
          </div>
          <div className="row mx-0 d-md-none">
            <div className="col-12 px-0">
              <ResponsiveNavBar/>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-12 px-0">
              {/* Content of te page */}
              <div className="container">
                <div className="row mt-50">
                  <div className="col-12">
                    <p className="h1-b">Nueva campaña</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-lg-10">
                    <div className="card">
                      <p className="h2-r mb-30 d-flex"><span class="material-icons-outlined md-24 mr-2 d-flex align-items-center">source</span> Datos generales</p>
                      <div className="row">
                        <div className="col-12">
                          <div className="input-group">
                            <input type="text" placeholder="Ej. Mi campaña de Abril" name='name' onChange={this.onChange} value={this.state.name} onBlur={this.validate_name} />
                            <label className="inputname">Nombre de la campaña:*</label>
                            <label className="errormesage">{this.state.invalid_name ? 'Ya existe una campaña con este nombre, elija un nombre diferente':(!this.state.name ? 'Debe dar un nombre a la campaña':'')}</label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 col-lg-6">
                          <div className="input-group">
                            <select name="destinataries" id="" placeholder="Ej. Pago de mantenimiento edificio A" onChange={this.onChange}>
                              
                              {/* {this.state.origin_phones_list && this.state.origin_phones_list.length > 1 &&
                                <option value="" selected disabled>Selecciona un remitente</option>
                              }
                              <option value={this.state.origin_phones_list[0]} key={0}>{ "fk" }</option>  
                              
                              
                              {this.state.origin_phones_list && this.state.origin_phones_list.length > 1 && this.state.origin_phones_list.map((element,i)=>{
                                if(i!=0){
                                  return(
                                    <option value={element.phone} key={i}>{element.phone - element.value }</option>
                                  )
                                }
                              })} */}
                              {this.state.origin_phones_list.map(item => {
                                  return (<option key={item.value} value={item.phone}>{item.value} - {item.phone}</option>);
                              })}
                              {/* <option value="1">Opcion 1</option>
                              <option value="2">Opcion 2</option>
                              <option value="3">Opcion 3</option>
                              <option value="4">Opcion 4</option> */}
                            </select>
                            <label className="inputname">De:*</label>
                            <label className="errormesage">{this.state.destinataries ? '':'Debes seleccionar una opción'}</label>
                          </div>
                        </div>

                        <div className="col-12 col-lg-6">
                          <div className="input-group">
                            <select name="template" id="" placeholder="Ej. Pago de mantenimiento edificio A" onChange={this.onChangeTemplate}>
                            <option value="" selected disabled>Selecciona una plantilla:*</option>
                              {this.state.template_options && this.state.template_options.length > 0 && this.state.template_options.map((template, i)=>{
                                return(
                                  <option value={i} key={i}>{template.template_name}</option>
                                )
                              })}
                              {/* <option value="" defaultValue>Selecciona un template</option>
                              <option value="1">Opcion 1</option>
                              <option value="2">Opcion 2</option>
                              <option value="3">Opcion 2</option>
                              <option value="4">Opcion 4</option> */}
                            </select>
                            <label className="inputname">Plantilla:*</label>
                            <label className="errormesage">{this.state.template ? '':'Debes seleccionar una opción'}</label>
                          </div>
                        </div>
                      </div>

                      {
                        this.state.template &&
                        <div className="row mt-30">
                          <div className="col-12">
                            <TemplateInfo
                              // className="d-none"
                              body={this.state.template.body_params}
                              url={this.state.template.header ? (this.state.template.header.url!=''  ? 'true' : 'false'): 'false'}
                              document={this.state.template.header ?  (this.state.template.header.documentFilename==null ? 'false' : 'true') : 'false'}
                              
                            />
                          </div>
                        </div>
                        
                      }

                        {!this.state.doc && 
                      <div className="row mt-20">
                          <div className="col-12 col-md-6 col-md-6 d-flex align-items-center">
                            <label htmlFor="file-upload" className="file-upload w-100 mb-0">importar archivo</label>
                            <input id="file-upload" type="file" name="myfile" onChange={this.loadExcel}   ref={this.inputRef} accept=".xlsx"/>
                            <a data-tip="Adjunta el archivo de Excel con los contactos solicitados y respetando la plantilla."><span class="material-icons-outlined md-36 c-primary ml-3">info</span></a>
                            <ReactTooltip place="bottom" type="dark" effect="float"/>
                          </div>
                      </div>
                        }
                        {this.state.doc &&
                          <div className="row mt-20">
                            <div className="col-12 col-md-6 col-md-6 d-flex align-items-center">
                              <label htmlFor="file-upload" className="file-upload w-100 mb-0">{this.state.doc.get("doc").name}</label>
                              {/* <input id="file-upload" type="file" name="myfile" onChange={this.loadExcel} name="primary_image"  ref={this.inputRef} accept=".xlsx"/> */}
                              <div className="col-4 col-md-3 d-flex">
                                  <button className="button -danger d-flex justify-content-center align-items-center w-100" onClick={this.reset_doc}><span class="material-icons-outlined md-24">backspace</span></button>
                              </div>
                              <a data-tip="Borra este archivo para volver a subirlo, si se han realizado cambios o si deseas subir otro archivo."><span class="material-icons-outlined md-36 c-primary ml-3">info</span></a>
                              <ReactTooltip place="bottom" type="dark" effect="float"/>
                            </div>
                          </div>
                        }

                      <div className="row mt-30">
                        <div className="col-12 col-md-6 d-flex align-items-center">
                          {this.state.doc &&
                          <div> 
                            
                            <button 
                              className="button -primary w-100" 
                              type="button" 
                              onClick={this.analyze} 
                              name="myfile" 
                              data-bs-toggle="modal" 
                              data-bs-target={this.readyAnalyze() ? "#none":"#analyze"}>Analizar archivo
                            </button>
                            {/* <a data-tip="Después de importar su archivo, analice su archivo para confirmar que funciona de forma correcta"><span class="material-icons-outlined md-36 c-primary ml-3">info</span></a> */}
                              </div>
                          }
                          
                          <ReactTooltip place="bottom" type="dark" effect="float"/>
                        </div>
                      </div>
                      {this.state.file_key && this.state.correct!=this.state.total &&
                      <div className="row mt-30">
                      <div className="col-12 col-md-6 d-flex align-items-center">
                        <a href={this.state.file_key}>
                        <button 
                          className="button -primary w-100" 
                          type="button" 
                          name="error_file">Descargar Archivo de Errores
                        </button>
                        </a>
                        <a data-tip="Revise el detalle de los registros con error."><span class="material-icons-outlined md-36 c-primary ml-3">info</span></a>
                        <ReactTooltip place="bottom" type="dark" effect="float"/>
                      </div>
                    </div>
                      }
                    </div>
                  </div>
                </div>

                <div className="row mt-20">
                  <div className="col-12 col-lg-10">
                    <div className="card">
                      <p className="h2-r mb-30 d-flex"><span class="material-icons-outlined md-24 mr-2 d-flex align-items-center">event</span> Agendar lanzamiento (Opcional)
                          <a data-tip="Permite agendar la fecha de envío de la campaña"><span class="material-icons-outlined md-36 c-primary ml-3">info</span></a>
                      </p>
                      <div className="row">
                        <div className="col-12 col-lg-6">
                            {/* <label className="inputname">Fecha de inicio:</label> */}
                          <div className="date-picker-wrapper">
                            {}
                            <DatePicker 
                              selected={this.state.date} 
                              onChange={this.onDateChange}
                              className="red-border"
                              minDate={new Date()}
                              maxDate={maxdate}
                              name={'date'}
                            />
                            <span class="material-icons">calendar_today</span>
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                        {/* <label className="inputname">Hora de inicio:</label> */}
                          <div className="date-picker-wrapper">
                            <DatePicker
                              selected={this.state.hour}
                              onChange={this.onHourChange}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={5}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              name={'hour'}
                            />
                            <span class="material-icons">schedule</span>
                          </div> 
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-12 col-lg-6">
                          <div className="input-group">
                            <input type="date" name='date' onChange={this.onChange} min={new Date().toISOString().split('T')[0]}/>
                            <label className="inputname">Fecha de inicio</label>
                          </div>
                        </div>

                        <div className="col-12 col-lg-6">
                          <div className="input-group">
                            <input type="time" name='hour' onChange={this.onChange}/>
                            <label className="inputname">Hora de inicio:</label>
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="row mt-20">
                        <div className="col-12 d-flex align-items-center">
                          <p className="h4-b mb-0 d-flex">Ventana de tiempo de entrega</p>
                          <a data-tip="Este es el periodo de tiempo durante el cuál se mandarán los mensajes Ej. L,M y M de 9:00 a 17:00 "><span class="material-icons-outlined md-36 c-primary ml-3">info</span></a>
                          <ReactTooltip place="bottom" type="dark" effect="float"/>
                        </div>
                      </div> */}

                      <div className="row">
                        {/* <div className="col-12 col-lg-6">
                          <div className="input-group">
                            <input type="time" />
                            <label className="inputname">Desde las:</label>
                            <label className="errormesage">Debes seleccionar una hora de inicio</label>
                          </div>
                        </div> */}

                        {/* <div className="col-12 col-lg-6">
                          <div className="input-group">
                            <input type="time"/>
                            <label className="inputname">Hasta las:</label>
                            <label className="errormesage">Debes seleccionar una hora de fin</label>
                          </div>
                        </div> */}
                      </div>

                      {/* <div className="row">
                        <div className="col-12">
                          <div className="input-group">
                            <Select
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              isMulti
                              options={options}
                              placeholder="Seleccionr días"
                            />
                            <label className="inputname">Días:</label>
                            <label className="errormesage">Debes seleccionar mínimo un día</label>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>

                <div className="row mt-20">
                  <div className="col-12 col-lg-10">
                    <div className="card">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center">
                          <p className="h2-r mb-0 d-flex"><span class="material-icons-outlined md-24 mr-2 d-flex align-items-center">call</span> Teléfono para pruebas</p>
                          <a data-tip="Permite mandar un mensaje de prueba al teléfono seleccionado"><span class="material-icons-outlined md-36 c-primary ml-3">info</span></a>
                          <ReactTooltip place="bottom" type="dark" effect="float"/>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="input-group">
                            <select name="test_phone" id="" placeholder="Selecciona un teléfono" onChange={this.onChange}>
                              {this.state.test_options && this.state.test_options.length > 1 &&
                                <option value="" selected disabled>Selecciona un remitente</option>
                              }
                              {this.state.test_options.length > 0 &&
                                <option value={this.state.test_options[0]} key={0}>{this.state.test_options[0]}</option>  
                              }
                              {this.state.test_options.map((element, index)=>{
                                if(index!=0){
                                  return(
                                    <option value={element} key={index}>{element}</option>
                                  )
                                }
                              })}
                            </select>
                            <label className="inputname">Selecciona un teléfono:</label>
                            {/* <label className="errormesage">{this.state.test_phone ? '':'Debes seleccionar una opción'}</label> */}
                          </div>
                        </div>
                        <div className="col-12 col-md-5">
                          <button className="button -secondary w-100 mt-30 w-100" data-bs-toggle="modal" data-bs-target={this.state.outbound_messages && this.state.outbound_messages.length>0 &&this.state.test_phone ?"#testCampaign":"#cantSend"}>Probar campaña</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-20">
                  <div className="col-12 col-md-4 col-lg-6">
                      <button className="button -primary w-100 mt-30" data-bs-toggle="modal" data-bs-target={this.state.tested && this.state.outbound_messages.length > 0 ?"#publishCampaign":"#cantSend"}>publicar campaña</button>
                  </div>
                  
                  {/* <div className="col-12 col-md-4 col-lg-3">
                    <button className="button -primary w-100 mb-20" onClick={this.reset_doc}>test</button>
                  </div> */}

                </div>
                <button data-bs-toggle="modal" data-bs-target="#errors" id={'error_display'} style={{display: this.state.showStore ? 'block' : 'none' }}/>
                <button data-bs-toggle="modal" data-bs-target="#loading" id={'loading_display'} style={{display: this.state.showStore ? 'block' : 'none' }}/>
              </div>
              {/* Content of te page */}
            </div>
          </div>

          <div className="row mx-0">
            <div className="col-12 px-0">
              <Footer
                className="mt-50"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewCampaign;