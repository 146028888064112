import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import SideNav from '../Components/Sidenav/Sidenav'
import NavBar from '../Components/NavBar/NavBar'
import ResponsiveNavBar from '../Components/ResponsiveNavBar/ResponsiveNavBar'
import Footer from '../Components/Footer/Footer';
import axios from 'axios';
import DatePicker from "react-datepicker";
import ReactTooltip from 'react-tooltip';
import csvDownload from 'json-to-csv-export'

const API_URL = process.env.REACT_APP_API_URL
const ACCESS_TOKEN = process.env.REACT_APP_ACCESS_TOKEN

class DownloadSurvey extends Component {

  constructor(props) {
    super(props);
    this.state = { 

      date_filter_start: '',
      date_filter_end:'',
      template_filter:'',
      date_range:'',

      surveys: [],
      headers: []

    }
  }

  toggle = () => {
    document.getElementById("newWorkspace").click();
  }

  dateFormat = (date) => {
    if(date === "" || date === undefined){
      return "";
    }else{
      return new Date(date).toISOString();
    }
  }


  dataDownload = () => {

    // Data for indicators

    axios({
        method: 'GET',
        url: `${API_URL}/outbound/survey`+`?&init_date=${this.dateFormat(this.state.date_filter_start)}&end_date=${this.dateFormat(this.state.date_filter_end)}&template_name=${this.state.template_filter}`,
        headers: {"X-Api-Key": ACCESS_TOKEN}
      })
      .then ( (res) => {
  
        let survey_response = res.data;
        
        console.log(res);

        survey_response.surveyData.forEach(element => {
            element.startDate = new Date(element.startDate).toLocaleDateString('es-MX', {year: 'numeric', month: 'long', day: 'numeric', hour:'numeric',minute:'numeric', second:'numeric'})
            element.campaignCreated = new Date(element.campaignCreated).toLocaleDateString('es-MX', {year: 'numeric', month: 'long', day: 'numeric', hour:'numeric',minute:'numeric', second:'numeric'})
        })

        const dataToConvert = {
            data: survey_response.surveyData,
            filename: 'reporte_encuestas',
            delimiter: ',',
            headers: survey_response.headerSurvey
        }
    
        csvDownload(dataToConvert)

      })
      .catch( (error) =>  {
        console.error('Error fount in:', (error))
      })

  }

  onDateChange = e => { this.setState({date_range:e, date_filter_start:e[0], date_filter_end:e[1]}) }

  render() {
    return (
    
    <div className="container-fluid px-0 d-flex">
        {this.state.redirect &&
            <Redirect to={'/optout-form'}></Redirect>
        }
        
        <SideNav
          {...this.props}
          className="d-none d-md-flex"
        />
        <div className="container-fluid px-0">
            <div className="row mx-0 d-none d-md-block">
                <div className="col-12 px-0">
                    <NavBar/>
                </div>
            </div>
            <div className="row mx-0 d-md-none">
                <div className="col-12 px-0">
                    <ResponsiveNavBar/>
                </div>
            </div>
            
            <div className="row mx-0 min-h-page">
                <div className="col-12 px-0">
                    <div className="container mt-50">
                        <div className="row">
                            <div className="col-12">
                                <p className="h1-b">Encuestas App</p>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="h2-r mb-30 d-flex"><span class="material-icons-outlined md-24 mr-2 d-flex align-items-center">download</span>Descarga de encuestas</p>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-12 col-lg-8">
                                            <label className="inputname">Buscar por fecha</label>
                                            
                                            <div>
                                                <div className="date-picker-wrapper">
                                                    <DatePicker 
                                                        onChange={this.onDateChange}
                                                        className="red-border"
                                                        maxDate={new Date()}
                                                        name={'date'}
                                                        startDate={this.state.date_filter_start}
                                                        endDate={this.state.date_filter_end}
                                                        selectsRange={true}
                                                        />
                                                    <span class="material-icons-outlined">calendar_today</span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-12 col-md-6 col-lg-4 d-flex">
                                            <a className="mt-30 w-100" data-tip="Buscar">
                                                <button className="button -primary d-flex justify-content-center align-items-center w-100" onClick={ this.dataDownload }><span class="material-icons-outlined md-24">download</span>Descarga</button>
                                            </a>
                                            <ReactTooltip place="bottom" type="dark" effect="float"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="row mx-0">
                <div className="col-12 px-0">
                    <Footer
                        className="mt-100"
                    />
                </div>
            </div>
        </div>
    </div>
    );
  }
}

export default DownloadSurvey;