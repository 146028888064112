import React, { Component } from 'react';

class componentName extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className={`uploaded-file ${this.props.className}`}>
        <div className="fileWrapper">
          <p className='file mb-0'>{this.props.fileName}</p>
        </div>
        <button className="delete" onClick={this.props.action}><span class="material-icons-outlined md-24">close</span></button>
      </div>
    );
  }
}

export default componentName;
