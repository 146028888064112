import React, { Component } from 'react';
import { PaginationItem, PaginationLink } from 'reactstrap';
import Pagination from '@mui/material/Pagination';

class Paginator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pages: [],
      pagesCount: 0,
      currentPage: 0
    }
  }

  componentDidUpdate(nextProps) {
    if(this.state.pages.length != this.props.size){

      var pages = [];
      for (var i = 0; i < this.props.size; i++) {
        pages.push(
          <PaginationItem>
          <PaginationLink onClick={e => this.handleClick(e, i)}>
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      )
    }
    this.setState({ pages: pages })
  }
  

    if (this.props.currentPage !== this.state.currentPage) {      
      this.setState({ currentPage: nextProps.currentPage })
    }
  }

  handleClick = (e, page) => {
    e.preventDefault();
    
    this.props.setPage(page-1)
    this.setState({ currentPage: page })
  }

  render() {
    return (
      <div className="paginator-component">
        <Pagination
          size="large"
          boundaryCount={5}
          count={this.props.size}
          variant='outlined'
          color='primary'
          page={this.props.currentPage}
          onChange={(e, page) => this.handleClick(e, page)}          
        />
        
      </div>
    );
  }
}

export default Paginator;