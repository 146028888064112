import { cardContentClasses } from '@mui/material';
import React, { Component } from 'react';
import SideNav from '../Components/Sidenav/Sidenav'
import NavBar from '../Components/NavBar/NavBar'
import ResponsiveNavBar from '../Components/ResponsiveNavBar/ResponsiveNavBar'
import DatePicker from "react-datepicker";
import ReactTooltip from 'react-tooltip';
import { Progress } from 'reactstrap';
import AnimatedNumber from "animated-number-react";
import axios from 'axios';
import Footer from '../Components/Footer/Footer'
import Paginator from '../Components/Paginator/Paginator'

const API_URL = process.env.REACT_APP_API_URL
const ACCESS_TOKEN = process.env.REACT_APP_ACCESS_TOKEN
const options = [
  { value: 'All', label: 'Todas' },
  { value: 'Zendesk', label: 'Formulario Zendesk' },
  { value: 'Risk', label: 'Riesgo - Prevencion de fraudes' },
  { value: 'WebService', label: 'Envío manual' }
];

class componentName extends Component {
  constructor(props) {
    super(props);

    this.state = {

      template_filter:"",
      user_filter:"",
      group_filter:"",
      category_filter:"",
      origin_phone_filter:"",
      platform_filter:"All",

      users:[],
      category:[],
      group:[],
      origin_phones_list:[],

      templates_data:[],

      date_filter_start:'',
      date_filter_end:'',
      date_range:'',

      value: 0,
      animation_duration: 500,
      total_msj_sending:0,
      total_filter_msj: 0,
      delivery_msjs:0,
      failure_msjs:0,
      agent_interaction:1,
      bot_interaction:1,
      current_month_msj:1,

      pendiing_msj:0,
      phone_no_wa:0,
      error_capture:0,

      interaction_msj:0,

      animation_bar:false,

      page_size:8,
      paginated_templates:[],
      current_page:0,
    };
  }

  onDateChange = e => { this.setState({date_range:e, date_filter_start:e[0], date_filter_end:e[1]}) }

  onChangeTemplate = e => this.setState({[e.target.name]: this.state.template_options[e.target.value]})

  onChange = e => { this.setState({ [e.target.name]: e.target.value }) }

  buttonFilter = e => {
    this.setState({ [e.target.name]: e.target.value, animation_bar:true })

    let template_filter = this.state.template_filter
    let user_filter = this.state.user_filter
    let date_filter_range = this.state.date_range
    let category_filter = this.state.category_filter
    let group_filter = this.state.group_filter
    let origin_phone_filter = this.state.origin_phone_filter
    let requestedBy_filter = this.state.platform_filter
    
    this.calculate_filter(template_filter, user_filter, date_filter_range, category_filter, group_filter, origin_phone_filter, requestedBy_filter)
  }

  calculate_filter = (template, user, date, category, group, origin_phone, platform_filter) => {
    if( origin_phone == "" ){
      let temp_array = this.state.phone_array;
      origin_phone = temp_array.join("-");
    }
    // Data for indicators
    axios({
      method: 'GET',
      url: `${API_URL}/Clip/GeneralIndicators`+`?attribute=&date_start=${this.dateFormat(date[0])}&date_end=${this.dateFormat(date[1])}&user=${user}&group=${group}&template=${template}&category=${category}&origin_phones=${origin_phone}&platform=${platform_filter}`
    })
    .then ( (res) => {

      let indicators = res.data;
      
      console.log(res);
      this.setState({ 
        total_msj_sending: indicators.total_msj, 
        total_filter_msj: indicators.total_filter_msj,
        delivery_msjs: indicators.delivery_success_msj, 
        failure_msjs: indicators.delivery_failed_msj,
        agent_interaction: indicators.agent_interaction,
        bot_interaction: indicators.clipbot_interaction,
        phone_no_wa: indicators.phone_no_wa,
        pendiing_msj:indicators.pending_recived_msj,
        error_capture:indicators.capture_error,
        current_month_msj:indicators.current_month_msj,
        templates_data:indicators.total_templates,
        animation_bar:false
      })
      this.paginate(indicators.total_templates)
    })
    .catch( (error) =>  {
      console.error('Error fount in:', (error))
    })
    
  }


  clear = () => {
    const date_start = new Date();
    const date_end = new Date();

    let date_filter_start = new Date(date_start.setDate(date_start.getDate() - 7));

    let dates_filter_range = [date_filter_start, date_end];
    this.setState({category_filter:"",user_filter:"",date_filter_start:date_filter_start, date_filter_end:date_end,date_range:dates_filter_range, group_filter:"", template_filter:"", origin_phone_filter:"", platform_filter:"All", animation_bar:true})
    this.calculate_filter("", "", dates_filter_range, "", "", "", "All")
    this.paginate(this.state.templates_data)
  }

  dateFormat = (date) => {
    if(date === "" || date === undefined){
      return "";
    }else{
      return new Date(date).toISOString();
    }
  }

  paginate = (campaigns) => {
    let page_size = this.state.page_size
    let page_numer = -1
    let paginated_templates = []
    for (let index = 0; index < campaigns.length; index++) {
      if(index%page_size == 0){
        page_numer = page_numer + 1
        paginated_templates.push([])
      }
      paginated_templates[page_numer].push(campaigns[index])
    }
    this.setState({paginated_templates, current_page:0})
  }

  setpage = (page) => {
    this.setState({current_page:page})
  }

  componentDidMount = () => {

    const date_start = new Date();
    const date_end = new Date();

    let date_filter_start = new Date(date_start.setDate(date_start.getDate() - 7));

    let dates_filter_range = [date_filter_start, date_end];

    this.setState({ date_filter_start: date_filter_start, date_filter_end: date_end, date_range:dates_filter_range});

    let phones_options = []
    let origin_phone_number = JSON.parse(localStorage.getItem("origin_phones"));
    let string_phone_array = []
    origin_phone_number.forEach(element => {
      if(element.value === "Stage_Clip_CE"){
        options.push(
          { value: 'Pipedrive', label: 'CE - Pipedrive' },
          { value: 'HubSpot', label: 'CE - HubSpot' },
        )
      }
      string_phone_array.push(element.phone);
      phones_options.push(element)
    });

    this.setState({phone_array:string_phone_array, origin_phones_list:phones_options})
    
    // Data for indicators
    axios({
      method: 'GET',
      url: `${API_URL}/Clip/GeneralIndicators`+`?attribute=&date_start=${this.dateFormat(date_filter_start)}&date_end=${this.dateFormat(date_end)}&user=${this.state.user_filter}&group=${this.state.group_filter}&template=${this.state.template_filter}&category=${this.state.category_filter}&origin_phones=${[]}&platform=${this.state.platform_filter}`
    })
    .then ( (res) => {

      let indicators = res.data;
      console.log("Indicators")
      console.log(res);
      this.setState({ 
        total_msj_sending: indicators.total_msj, 
        total_filter_msj: indicators.total_filter_msj,
        delivery_msjs: indicators.delivery_success_msj, 
        failure_msjs: indicators.delivery_failed_msj,
        agent_interaction: indicators.agent_interaction,
        bot_interaction: indicators.clipbot_interaction,
        phone_no_wa: indicators.phone_no_wa,
        pendiing_msj:indicators.pending_recived_msj,
        error_capture:indicators.capture_error,
        current_month_msj:indicators.current_month_msj,
        templates_data:indicators.total_templates 
      })
      this.paginate(indicators.total_templates )
    })
    .catch( (error) =>  {
      console.error('Error fount in:', (error))
    })

    // Templates
    axios({
      method: 'GET',
      url: API_URL + '/templates/outbound/sunshine?template_case=filter',
      headers: {"X-Api-Key": ACCESS_TOKEN}
    })
    .then( (res) => {
      // let template_options = [{template_name:'Selecciona un template'}].concat(res.data.response)
      
      console.log(res.data)
      let templates_sorted = res.data.response.sort((a, b) => (a.template_name > b.template_name) ? 1 : -1)
      this.setState({template_options:templates_sorted})
    }).catch(error => {
      console.error('Error found in:', (error));
    });  

    // Campaigns data filter
    axios({
      method: 'GET',
      url: `${API_URL}/clip/campaigns/outbound?origin_phone=${string_phone_array.join("-")}`,
      headers: {"X-Api-Key": ACCESS_TOKEN}
    })
    .then( (res) => {
      let dates = []
      let users = []
      let category = []
      let group = []

      let c = res.data.response

      for (let index = 0; index < c.length; index++) {
        if(users.indexOf(c[index].user) === -1 && c[index].requestedBy !== "Risk" ){
          users.push(c[index].user)
        }
        if(category.indexOf(c[index].category) === -1  ){
          category.push(c[index].category)
        }
        if(group.indexOf(c[index].group) === -1 ){
          if( c[index].group !== null )
          {
            group.push(c[index].group)
          }
        }
        dates.push(new Date(c[index].created))
      }
      this.setState({ users, dates, category, group })

    }).catch(error => {
      console.error('Error found in:', (error));
    });   
    

  }

  render() {
    return (
      <div>
        <div className="container-fluid px-0 d-flex">
          <SideNav
            {...this.props}
            className="d-none d-md-flex"
          />
          <div className="container-fluid px-0">
            <div className="row mx-0 d-none d-md-block">
              <div className="col-12 px-0">
                <NavBar/>
              </div>
            </div>
            <div className="row mx-0 d-md-none">
              <div className="col-12 px-0">
                <ResponsiveNavBar/>
              </div>
            </div>
            <div className="row mx-0 min-h-page">
              <div className="col-12 px-0">
                <div className="container mt-50">
                  <div className="row mt-50">
                    <div className="col-12">
                      <p className="h1-b">Indicadores</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="row">
                          <div className="col-12">
                            <p className="h2-r mb-30 d-flex"><span class="material-icons-outlined md-24 mr-2 d-flex align-items-center">email</span> Total de mensajes enviados</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-12 col-lg-3">
                            {/* <div className="input-group">
                              <input type="date" name='date_filter' value={this.state.date_filter} onChange={this.onChange} max={new Date().toISOString().split('T')[0]}/>
                            </div> */}
                            <label className="inputname">Buscar por fecha</label>
                            <div>
                              <div className="date-picker-wrapper">
                                      <DatePicker 
                                        // selected={this.state.date_filter_start} 
                                        onChange={this.onDateChange}
                                        className="red-border"
                                        maxDate={new Date()}
                                        name={'date'}
                                        startDate={this.state.date_filter_start}
                                        endDate={this.state.date_filter_end}
                                        selectsRange={true}
                                        />
                                      <span class="material-icons-outlined">calendar_today</span>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-lg-3">
                            <div className="input-group">
                              <select name="user_filter" id="" placeholder="Selecciona un teléfono" onChange={this.onChange} value={this.state.user_filter}>
                              <option value="" >Todas</option>
                                {this.state.users.map((element, index)=>{
                                  return(
                                    <option value={element} key={index}>{element}</option>
                                  )
                                })}
                              </select>
                              <label className="inputname">Agente</label>
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-lg-2">
                            <div className="input-group">
                              <select name="group_filter" id="" placeholder="Selecciona un grupo"onChange={this.onChange} value={this.state.group_filter}>
                                <option value="" >Todas</option>
                                {this.state.group.map((element, index)=>{
                                  return(
                                    <option value={element} key={index}>{element}</option>
                                  )
                                })}
                              </select>
                              <label className="inputname">Grupo</label>
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-lg-2">
                            <div className="input-group">
                              <select name="template_filter" id="" placeholder="Selecciona una plantilla" onChange={this.onChange} value={this.state.template_filter}>
                                <option value="" >Todas</option>
                                {this.state.template_options && this.state.template_options.length > 0 && this.state.template_options.map((template, i)=>{
                                  return(
                                    <option value={template.template_name} key={i}>{template.template_name}</option>
                                  )
                                })}
                              </select>
                              <label className="inputname">Plantilla</label>
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-lg-2">
                            <div className="input-group">
                              <select name="category_filter" id="" placeholder="Selecciona un teléfono"onChange={this.onChange} value={this.state.category_filter}>
                                <option value="" >Todas</option>
                                {this.state.category.map((element, index)=>{
                                  return(
                                    <option value={element} key={index}>{element}</option>
                                  )
                                })}
                              </select>
                              <label className="inputname">Categoría</label>
                            </div>
                          </div> 
                        </div>

                        <div className="row mt-20">
                          {/* <div className="col-12 col-md-6 col-lg-3">
                            <div className="input-group">
                              <select name="origin_phone_filter" id="" placeholder="Selecciona un teléfono" onChange={this.onChange} value={this.state.origin_phone_filter}>
                              <option value="" >Todas</option>
                                {this.state.origin_phones_list.map(item => {
                                    return (<option key={item.value} value={item.phone}>{item.value} - {item.phone}</option>);
                                })}
                              </select>
                              <label className="inputname">Teléfono Origen</label>
                            </div>
                          </div> */}

                          <div className="col-12 col-md-6 col-lg-3">
                            <div className="input-group">
                              <select name="platform_filter" id="" placeholder="Selecciona un teléfono" onChange={this.onChange} value={this.state.platform_filter}>
                                  {options.map(item => {
                                    return (<option key={item.value} value={item.value}>{item.label}</option>);
                                })}
                              </select>
                              <label className="inputname">Método de envío</label>
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-lg-2 d-flex">
                            <a className="mt-30 w-100" data-tip="Buscar">
                              <button className="button -primary d-flex justify-content-center align-items-center w-100" onClick={this.buttonFilter}><span class="material-icons-outlined md-24">search</span></button>
                            </a>
                            <ReactTooltip place="bottom" type="dark" effect="float"/>
                          </div>

                          <div className="col-12 col-md-6 col-lg-2 d-flex">
                            <a className="mt-30 w-100" data-tip="Eliminar búsqueda">
                              <button className="button -danger d-flex justify-content-center align-items-center w-100" onClick={this.clear}><span class="material-icons-outlined md-24">backspace</span></button>
                            </a>
                            <ReactTooltip place="bottom" type="dark" effect="float"/>
                          </div>
                        </div>

                        <div className="row mt-30">
                          <div className="col-12">
                            {this.state.animation_bar && <AnimatedNumber
                              className="h1"
                              value={this.state.total_msj_sending}
                              duration={this.state.animation_duration}
                              style={{
                                transition: '0.8s ease-out',
                              }}
                              /> }
                              
                            {!this.state.animation_bar && <AnimatedNumber
                              className="h1"
                              value={this.state.total_filter_msj}
                              duration={this.state.animation_duration}
                              style={{
                                transition: '0.8s ease-out',
                              }}
                            /> } de {this.state.total_msj_sending}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            {this.state.animation_bar &&
                              <Progress
                                animated
                                className='-xl'
                                max={this.state.total_msj_sending}
                                value={this.state.total_msj_sending}/>
                            }
                            {!this.state.animation_bar &&
                              <Progress
                                className='-xl'
                                max={this.state.total_msj_sending}
                                value={this.state.total_filter_msj}/>
                            }
                          </div>
                        </div>
                        {( localStorage.getItem('role') === "1" || localStorage.getItem('role') === "2" || localStorage.getItem('role') === "4") && <div className="row mt-20">
                          <div className="col-12">
                          <a className="mt-auto w-100" data-tip="Generar documento">
                          <button className="button -primary d-flex justify-content-center align-items-center w-100" onClick={() => window.open(API_URL + '/Clip/Download/IndicatorsReport' +`?date_start=${this.dateFormat(this.state.date_range[0])}&date_end=${this.dateFormat(this.state.date_range[1])}&user=${this.state.user_filter}&group=${this.state.group_filter}&template=${this.state.template_filter}&category=${this.state.category_filter}&origin_phones=&platform=${this.state.platform_filter}`, "_blank" )} ><i class="fas fa-download"></i> Descargar Reporte</button>
                          </a>
                          <ReactTooltip place="bottom" type="dark" effect="float"/>
                          </div>
                        </div>}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-lg-6 d-flex">
                      <div className="card mt-20 w-100">
                        <div className="row">
                          <div className="col-12">
                            <p className="h2-r mb-30 d-flex"><span class="material-icons-outlined md-24 mr-2 d-flex align-items-center">task_alt</span> Estatus de enviados</p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <p className="h4 mb-0">Mensajes entregados:</p>
                          </div>
                          <div className="col-12">
                            <p className="h3-b mb-0">{this.state.delivery_msjs}</p>
                          </div>
                        </div>

                        <div className="row mt-20">
                          <div className="col-12">
                            <p className="h4 mb-0">Mensajes no entregados:</p>
                          </div>
                          <div className="col-12">
                            <p className="h3-b">{this.state.failure_msjs}</p>
                          </div>
                        </div>

                        <div className="row mt-20">
                          <div className="col-12 col-lg-6">
                            <p className="h4 mb-0">Número sin cuenta Whatsapp:</p>
                            <p className="h4-b c-danger">{this.state.phone_no_wa}</p>
                          </div>
                          <div className="col-12 col-lg-6">
                            <p className="h4 mb-0">Mensajes pendientes:</p>
                            <p className="h4-b c-danger">{this.state.pendiing_msj}</p>
                          </div>
                          <div className="col-12 col-lg-6">
                            <p className="h4 mb-0">Error de captura:</p>
                            <p className="h4-b c-danger">{this.state.error_capture}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-lg-6 d-flex">
                      <div className="card mt-20 w-100">
                        <div className="row">
                          <div className="col-12">
                            <p className="h2-r mb-30 d-flex"><span class="material-icons-outlined md-24 mr-2 d-flex align-items-center">touch_app</span> Interacciones</p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <p className="h4 mb-0">Agente</p>
                          </div>
                          <div className="col-12">
                            <p className="h3-b mb-0">{this.state.agent_interaction}</p>
                          </div>
                        </div>

                        <div className="row mt-20">
                          <div className="col-12">
                            <p className="h4 mb-0">Asistente virtual</p>
                          </div>
                          <div className="col-12">
                            <p className="h3-b">{this.state.bot_interaction}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 d-flex">
                      <div className="card mt-20 w-100">
                        <div className="row">
                          <div className="col-12">
                            <p className="h2-r mb-30 d-flex"><span class="material-icons-outlined md-24 mr-2 d-flex align-items-center">task_alt</span>Mensajes por plantilla</p>
                          </div>
                        </div>
                        <table className='justify-content-between'>
                          <thead>
                            <tr>
                              <th>Template</th>
                              <th>
                                <tr>Total de mensajes</tr>
                                <tr>
                                  <td>#</td>
                                  <td>%</td>
                                </tr>
                              </th>
                              <th>
                                <tr>Total de respuestas</tr>
                                <tr>
                                  <td>#</td>
                                  <td>%</td>
                                </tr>
                              </th>
                              <th>
                                <tr>Mensajes enviados al mes</tr>
                                <tr>
                                  <td>#</td>
                                  <td>%</td>
                                </tr>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            { this.state.paginated_templates.length > 0 &&
                              this.state.paginated_templates[this.state.current_page].map((item, i) => {
                                return [
                                  <tr>
                                    <td>{item._id}</td>
                                    <td>
                                      <div className="row">
                                        <p className='col-6'>{item.total}</p>
                                        <p className='col-6 t-bold c-primary ml-auto'>{((item.total / this.state.total_filter_msj ) * 100).toFixed(2) }%</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="row">
                                        <p className='col-6'>{item.total_interactions}</p>
                                        <p className='col-6 t-bold c-primary ml-auto'>{((item.total_interactions / (this.state.bot_interaction + this.state.agent_interaction) ) * 100).toFixed(2) }%</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="row">
                                        <p className='col-6'>{item.template_by_month}</p>
                                        <p className='col-6 t-bold c-primary ml-auto'>{((item.template_by_month / this.state.current_month_msj ) * 100).toFixed(2) }%</p>
                                      </div>
                                    </td>
                                  </tr>
                                ];    
                              })
                            }
                          </tbody>
                        </table>
                        <div className="row">
                          <div className="col-12 d-flex justify-content-center align-items-center">
                            <Paginator
                              page_size={this.state.page_size}
                              size={this.state.paginated_templates.length}
                              current_page={this.state.current_page}
                              setPage={this.setpage}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mx-0">
              <div className="col-12 px-0">
                <Footer
                  className="mt-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default componentName;