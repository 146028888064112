import React, { Component } from 'react';

class CampaignBar extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }

  render() {
    return (
      <a href={this.props.link} className="campaign-bar">
        <div className={`content ${this.props.className}`}>
          <div className="row">
            <div className="col-12 col-md-5">
              <p className="text name h3-b">{this.props.campaignName}</p>
              <p className="text body">Creado el: {new Date(this.props.date).toLocaleDateString('es-MX',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>
              {this.props.scheduled && this.props.scheduled != this.props.date &&
                <p className="text body">Lanzamiento: {new Date(this.props.scheduled).toLocaleDateString('es-MX',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour:'numeric',minute:'numeric' })}</p>

              }
            </div>
            <div className="col-12 col-md-1">
              <p className="text -scope h2-b">{this.props.scope}</p>
            </div>
            <div className="col-12 col-md-2">
              <p className="text body">{this.props.campaignState}</p>
              {/* <p className="text body">{parseInt(this.props.percentage)}%</p> */}
            </div>
            <div className="col-12 col-md-3">
              <p className="text body">{this.props.creator}</p>
            </div>
          </div>
        </div>
      </a>
    );
  }
}

export default CampaignBar;