import React, { Component } from 'react';
import './App.scss';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Callback from './auth/Callback';

//IMPORT PAGES//
import Home from './Pages/Home';
import NewCampaign from './Pages/NewCampaign';
import CampaignDetail from './Pages/CampaignDetail';
import Users from './Pages/Users';
import NewUser from './Pages/NewUser';
import Indicators from './Pages/Indicators';
import Analytics from './Pages/Analytics';
import OptOutUsers from './Pages/OptOutUsers';
import NewOptoutUser from './Pages/NewOptoutUser';
import DownloadSurvey from './Pages/DownloadSurvey';

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      updating: false
    }
  }
  render () {
    return (
      <Router>
      <Switch>
        <Route path ="/home" render ={(...props) =>(
          this.props.auth.isAuthenticated() ? (
            <Home {...this.props}/>
          ): (
            this.props.auth.login()
          )
          )}/>
        <Route path ="/new-campaign" render ={(...props) =>(
          this.props.auth.isAuthenticated() ? (
            <NewCampaign {...this.props}/>
          ): (
            <Redirect to="/home" />
          )
          )}/>
          <Route path="/callback" render={(...props) => (
            <Callback {...this.props} />
            )} />
        <Route path ="/campaign-detail/:id" render ={(...props) =>(
          this.props.auth.isAuthenticated() ? (
            <CampaignDetail location={props} {...this.props} />
          ): (
            <Redirect to="/home" />
          )
          )}/>
        <Route path ="/users" render ={(...props) =>(
          this.props.auth.isAuthenticated() ? (
          <Users location={props} {...this.props} />
          ): (
            <Redirect to="/home" />
          )
        )}/>
        <Route path ="/new-user" render ={(...props) =>(
          this.props.auth.isAuthenticated() ? (
          <NewUser location={props} {...this.props} />
          ): (
            <Redirect to="/home" />
          )
        )}/>
        <Route path ="/indicators" render ={(...props) =>(
          this.props.auth.isAuthenticated() ? (
          <Indicators location={props} {...this.props} />
          ): (
            <Redirect to="/home" />
          )
        )}/>
        <Route path ="/analytics" render ={(...props) =>(
          this.props.auth.isAuthenticated() ? (
          <Analytics location={props} {...this.props} />
          ): (
            <Redirect to="/home" />
          )
        )}/>
        <Route path ="/optout" render ={(...props) =>(
          this.props.auth.isAuthenticated() ? (
          <OptOutUsers location={props} {...this.props} />
          ): (
            <Redirect to="/home" />
          )
        )}/>
        <Route path ="/optout-form" render ={(...props) =>(
          this.props.auth.isAuthenticated() ? (
          <NewOptoutUser location={props} {...this.props} />
          ): (
            <Redirect to="/home" />
          )
        )}/>
        <Route path ="/download-survey" render ={(...props) =>(
          this.props.auth.isAuthenticated() ? (
          <DownloadSurvey location={props} {...this.props} />
          ): (
            <Redirect to="/home" />
          )
        )}/>
      <Redirect from="/" to="/home" />
      </Switch>
    </Router>
  );
  }
}

export default App;