import React, { Component } from 'react';
import SideNav from '../Components/Sidenav/Sidenav'
import NavBar from '../Components/NavBar/NavBar'
import ResponsiveNavBar from '../Components/ResponsiveNavBar/ResponsiveNavBar'
import PermissionsCard from '../Components/PermissionsCard/PermissionsCard';
import Footer from '../Components/Footer/Footer'

class NewUser extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      checkedTextSA: '',
      checkedIconSA: 'd-none',
      checkedTextA: '',
      checkedIconA: 'd-none',
      checkedTextE: '',
      checkedIconE: 'd-none',
      modal:false,

    }
  }

  toggle = () => {
    document.getElementById("newWorkspace").click();
  }

  checkPermissionSA = () => {
    if (this.state.checkedTextSA === '' ) {
      this.setState ({
        checkedTextSA:'d-none',
        checkedIconA: 'd-none',
        checkedIconE: 'd-none',
        checkedTextA: '',
        checkedTextE: '',
    })
    } 
    else {
      this.setState ({checkedTextSA:''})
    }

    if (this.state.checkedIconSA === '' ) {
      this.setState ({checkedIconSA:'d-none'})
    } 
    else {
      this.setState ({checkedIconSA:''})
    }
  }
  
  checkPermissionA = () => {
    if (this.state.checkedTextA === '' ) {
      this.setState ({
        checkedTextA:'d-none',
        checkedIconSA: 'd-none',
        checkedIconE: 'd-none',
        checkedTextSA: '',
        checkedTextE: '',
    })
    } 
    else {
      this.setState ({checkedTextA:''})
    }

    if (this.state.checkedIconA === '' ) {
      this.setState ({checkedIconA:'d-none'})
    } 
    else {
      this.setState ({checkedIconA:''})
    }
  } 

  checkPermissionE = () => {
    if (this.state.checkedTextE === '' ) {
      this.setState ({
        checkedTextE:'d-none',
        checkedIconSA: 'd-none',
        checkedIconA: 'd-none',
        checkedTextSA: '',
        checkedTextA: '',
    })
    } 
    else {
      this.setState ({checkedTextE:''})
    }

    if (this.state.checkedIconE === '' ) {
      this.setState ({checkedIconE:'d-none'})
    } 
    else {
      this.setState ({checkedIconE:''})
    }
  } 

  render() {
    return (
      <div className="container-fluid px-0 d-flex"> 
        <div class="modal fade " id="newWorkspace" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="h1-b" id="exampleModalLabel">Crear nuevo workspace</h5>
                <button type="button" class="button btn-close" data-bs-dismiss="modal" aria-label="Close"><span class="material-icons-outlined md-24">close</span></button>
              </div>
              <div class="modal-body">
                <div className="row">
                  <div className="col-12 ">
                    <div className="input-group">
                      <input type="text" name='name' placeholder="Ej. Espacio de trabajo XYZ"/>
                      <label className="inputname">Nombre del workspace</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="button -text" data-bs-dismiss="modal">cancelar</button>
                <button type="button" class="button -primary" onClick={this.sendTest}>Crear workspace</button>
              </div>
            </div>
          </div>
        </div>

        <SideNav
          {...this.props}
          className="d-none d-md-flex"
        />
        <div className="container-fluid px-0">
          <div className="row mx-0 d-none d-md-block">
            <div className="col-12 px-0">
              <NavBar/>
            </div>
          </div>
          <div className="row mx-0 d-md-none">
            <div className="col-12 px-0">
              <ResponsiveNavBar/>
            </div>
          </div>
          <div className="row mx-0 min-h-page">
            <div className="col-12 px-0">
              <div className="container mt-50">
                <div className="row">
                  <div className="col-12">
                    <p className="h1-b">Agregar nuevo usuario</p>
                  </div>
                </div>

                <div className="row mt-50">
                  <div className="col-12">
                    <p className="h3-b mb-20">Información del usuario</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-4 mb-20">
                    <div className="input-group">
                      <input type="text" name='name' />
                      <label className="inputname">Nombre (obligatorio)</label>
                      <label className="errormesage">Este campo debe llenarse</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-20">
                    <div className="input-group">
                      <input type="text" name='namep' />
                      <label className="inputname">Apellido Paterno (obligatorio)</label>
                      <label className="errormesage">Este campo debe llenarse</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-20">
                    <div className="input-group">
                      <input type="text" name='namem' />
                      <label className="inputname">Apellido Materno (obligatorio)</label>
                      <label className="errormesage">Este campo debe llenarse</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="input-group">
                      <input type="email" name='email' />
                      <label className="inputname">Correo electrónico (obligatorio)</label>
                      <label className="errormesage">Este campo debe llenarse</label>
                    </div>
                  </div>
                </div>

                <div className="row mt-50">
                  <div className="col-12">
                    <p className="h3-b">Permisos</p>
                    <p className="body mb-20">Selecciona los permisos que el usuario tendrá dentro de la plataforma</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-lg-4 d-flex">
                    <PermissionsCard
                      className="mb-20"
                      checkedText = {this.state.checkedTextSA}
                      checkedIcon= {this.state.checkedIconSA}
                      checkPermission = {this.checkPermissionSA}
                      permissions="SuperAdmin"
                    />
                  </div>
                  <div className="col-12 col-lg-4 d-flex">
                    <PermissionsCard
                      className="mb-20"
                      checkedText = {this.state.checkedTextA}
                      checkedIcon= {this.state.checkedIconA}
                      checkPermission = {this.checkPermissionA}
                      permissions="Admin"
                    />
                  </div>
                  <div className="col-12 col-lg-4 d-flex">
                    <PermissionsCard
                      className="mb-20"
                      checkedText = {this.state.checkedTextE}
                      checkedIcon= {this.state.checkedIconE}
                      checkPermission = {this.checkPermissionE}
                      permissions="Editor"
                    />
                  </div>
                </div>

                <div className="row mt-50">
                  <div className="col-12">
                    <p className="h3-b">Workspaces</p>
                    <p className="body mb-20">Selecciona el workspace en dónde el usuario estará trabajando, Si no existe aún el worplace, puedes crearlo a continuación</p>
                  </div>
                </div>

                <div className="row align-items-center">
                  <div className="col-12 col-lg-6 mb-20">
                    <div className="input-group">
                      <select name="" id="">
                        <option value="">Actinver Interno</option>
                        <option value="">DINN Externo</option>
                      </select>
                      <label className="inputname">Seleccionar</label>
                      <label className="errormesage">Debes seleccionar una opción</label>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 mb-20">
                  <button className="button -text p-0 d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#newWorkspace" ><span class="material-icons-outlined mr-2">add_circle_outline</span> CREAR WORKSPACE</button>
                  </div>
                </div>

                <div className="row mt-50">
                  <div className="col-12 col-md-6 col-lg-4">
                    <button className="button -primary w-100">Crear usuario</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mx-0">
            <div className="col-12 px-0">
              <Footer
                className="mt-100"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewUser;