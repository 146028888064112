import history from '../utils/history';
import auth0 from 'auth0-js';
import { AUTH_CONFIG } from './auth0-variables';
import jwtDecode from 'jwt-decode'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export default class Auth {
  accessToken;
  idToken;
  expiresAt;

  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientID,
    redirectUri: AUTH_CONFIG.callbackUrl,
    audience: AUTH_CONFIG.audience,
    responseType: 'token id_token',
    scope: 'openid'
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.renewSession = this.renewSession.bind(this);
  }

  login() {
    console.log(AUTH_CONFIG)
    this.auth0.authorize();
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        let user_id = jwtDecode(authResult.idToken).sub.replace('auth0|','')
        let url = API_URL +'/testPhones?user_id=' + user_id
        console.log('Getting profile', url, user_id)
        axios.get(url, {
          user_id
        }).then((res) => {
          localStorage.setItem("phone_numbers", res.data.phone_numbers);
          localStorage.setItem("email", res.data.email);
          localStorage.setItem("user_name", res.data.name);
          localStorage.setItem("origin_phones", JSON.stringify(res.data.origin_phones) );
          localStorage.setItem("role", res.data.web_service_role);
          history.replace('/seller');
          window.location.reload();
        })
      } else if (err) {
        history.replace('/');
        window.location.reload();
        console.log(err);
        alert(`Error: ${err.error}. Check the console for further details.`);
      }
    });
  }

  getAccessToken() {
    return this.accessToken;
  }

  getIdToken() {
    return this.idToken;
  }

  setSession(authResult) {
    // Set isLoggedIn flag in localStorage
    localStorage.setItem('isLoggedIn', 'true');

    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.expiresAt = expiresAt;
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);

    // navigate to the home route
    history.replace('/');
  }

  renewSession() {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        this.logout();
        console.log(err);
        alert(
          `Could not get a new token (${err.error}: ${err.error_description}).`
        );
      }
    });
  }

  logout() {
    // Remove tokens and expiry time
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;

    // Remove isLoggedIn flag from localStorage
    localStorage.removeItem('isLoggedIn');
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('rules')
    localStorage.removeItem('user_id')
    localStorage.removeItem('name')
    localStorage.removeItem('email')
    localStorage.removeItem('phone_numbers')
    localStorage.removeItem('origin_phones')
    localStorage.removeItem("role");
    localStorage.removeItem('first_name')
    localStorage.removeItem('last_name ')
    localStorage.removeItem('phone')
    localStorage.removeItem('role')
    localStorage.removeItem('agency')
    localStorage.removeItem('card')
    // navigate to the home route
    // history.replace('/feed');
    // window.location.reload();

    this.auth0.logout({
      returnTo: AUTH_CONFIG.logoutUrl,
      clientID: AUTH_CONFIG.clientID
    });

    // navigate to the home route
    history.replace('/');
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  getProfile(cb) {
    let accessToken = this.getAccessToken();
    this.auth0.client.userInfo(accessToken, (err, profile) => {
      if (profile) {
        this.userProfile = profile;
      }
      cb(err, profile);
    });
  }
}
