import React, { Component } from 'react';
import SideNav from '../Components/Sidenav/Sidenav'
import NavBar from '../Components/NavBar/NavBar'
import ResponsiveNavBar from '../Components/ResponsiveNavBar/ResponsiveNavBar'
import Footer from '../Components/Footer/Footer'
import Constructor from '../Components/Constructor/Constructor';
import Lottie from 'react-lottie';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import * as xlsx from "xlsx";
import jwtDecode from 'jwt-decode';
import FileSaver from "file-saver";
import UploadedFile from '../Components/UploadedFile/UploadedFile';
import ImgSucces from '../img/success_post.svg';
import animationData from '../img/lottie_files/Analyze.json';

import BannerUsers from '../img/Banner_users.svg'
import { data } from 'jquery';
const API_URL = process.env.REACT_APP_API_URL

class OptOutUser extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      excelLoadedData:[],
      total:0,
      correct:0,
      analyzerData:[],
      data:[],

      loading:false,
      analyzer:false
     }
  }

  reset_doc = () => {
    this.setState({doc:'', tested:false, analyzed:false, excelLoadedData:[], file_name:'', total:0, correct:0, data:[], analyzerData:[], analyzer:false},()=>{
      document.getElementById("file-upload").value=''
    });
  }

  downloadList = () => {

    axios({
      method: 'GET',
      url: API_URL+ `/Clip/Download/Optout?user_id=${jwtDecode(localStorage.getItem("id_token")).sub.replace('auth0|','')}`,
    })
    .then( (res) => {
      console.log(res)
      var ws = xlsx.utils.json_to_sheet(res.data);

      var csv = xlsx.utils.sheet_to_csv(ws);

      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      }
      
      FileSaver.saveAs(new Blob([s2ab(csv)],{type:"application/octet-stream"}), "Lista usuarios.csv");

    }).catch(error => {
      console.error(error)
      console.log(error)

    });
    
  }

  loadExcelToJson = (e) => {

    let file;

    try {
      file = e.target.files[0];
    } catch {
      file = e
    }

    if( e.target.files ){
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read( data, { type: "array" } );
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);

        console.log(xlsx);

        let document = new FormData()
        document.append('doc',file)
        document.append('file',data)

        console.log(json);
        this.setState({
          excelLoadedData: json,
          doc: document
        })
      };
      reader.readAsArrayBuffer(e.target.files[0]);

    }
  }

  submitForm = () => {
    window.location.href = "/optout-form";
  }

  downloadErrorFile = () => {

    let fileError = this.state.analyzerData;

    var ws = xlsx.utils.json_to_sheet(fileError);

    var csv = xlsx.utils.sheet_to_csv(ws);

    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }
    
    FileSaver.saveAs(new Blob([s2ab(csv)],{type:"application/octet-stream"}), "errores usuarios.csv");

  }


  downloadEmptyTemplate = () => {

    var empty = [{
      "":1,
      "me_phone":"5555555555",
      "email":"ejemplo@nds.com"
    }]

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    let header = ["", "ME_Phone", "Email"];

    const ws = xlsx.utils.book_new();

    xlsx.utils.sheet_add_aoa(ws, [header]);

    xlsx.utils.sheet_add_json(ws, empty, { origin: 'A2', skipHeader: true });

    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

    const excelBuffer = xlsx.write(wb, { bookType: "xlsx", type: 'array', cellStyles:true });

    const finalData = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(finalData, "plantilla ejemplo.xlsx");

  }

  sendDoc = () => {

    let user_doc = []

    this.state.data.forEach(element => {
      let temp = {}
      temp['agent_name'] = localStorage.getItem("user_name");
      temp['channel'] = "whatsapp";
      temp['outbound_status'] = 'inactivo';
      temp['date'] = new Date().toISOString();
      temp['me_phone'] = element['ME_Phone']+"";
      temp['email'] = element['Email'];

      user_doc.push(temp);
    });

    let data_json = JSON.stringify({ 
      data: user_doc
    });

    let json = JSON.parse(data_json)

    console.log(json)
    
    axios({
      method: 'POST',
      url: API_URL+ '/Clip/Optout/web',
      data:json
    })
    .then( (res) => {
      console.log(res)
      this.reset_doc();
      // this.setState({
      //   file_key:`${storage_url}/${res.data.key}?authuser=0`, 
      //   outbound_messages:res.data.success,
      //   total:res.data.length + 1,
      //   correct: res.data.success.length,
      //   errors:res.data.errors,
      //   loading:false,
      //   analyzed:true
      // })
      // if(res.data.error_msg.length > 0) document.getElementById("error_display").click();
    }).catch(error => {
      console.error(error)
      console.log(error)
      this.reset_doc()
      // this.setState({loading:false, excel_error:true})
    });
  }

  excelAnalyzer = () => {
    let excelData = this.state.excelLoadedData;
    let dataAnalyzer = [];
    let validData = [];
    let errorCount = 0;

    
    excelData.forEach( element => {
        let errorStr = "";
        let temp = element;
        let phoneStr = element['ME_Phone']+"";

        if ( element['Email'] === null || element['Email'] === undefined || element['Email'] === "" )
        {
          errorStr += "Atributo Email necesario. ";
          element['Email'] = ""
        }

        if( element['ME_Phone'] === null || element['ME_Phone'] === undefined || element['ME_Phone'] === "")
        {
          errorStr +="Atributo ME_Phone necesario. " 
          element['ME_Phone'] = ""
        }else if( phoneStr.length < 10 || phoneStr.length > 10 )
        {
          errorStr +="Atributo ME_Phone debe contener 10 dígitos. ";
        }

        if( errorStr !== "" )
        {
          errorCount += 1;
        }else{
          validData.push(temp);
        }

        element['Error'] = errorStr;
        dataAnalyzer.push(element);

    })

    this.setState({
      correct: validData.length,
      analyzerData: dataAnalyzer,
      data: validData,
      total: excelData.length,
      analyzer:true
    })

  }

  render() {

    const analyze = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      }
    }

    return (
      <div className="container-fluid px-0 d-flex">
        {/* Missing data to analyze document */}
        <div class="modal fade " id="uploadFile" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="h1-b" id="exampleModalLabel">Rechazado</h5>
                <button type="button" class="button btn-close" data-bs-dismiss="modal" aria-label="Close"><span class="material-icons-outlined md-24">close</span></button>
              </div>
              
              <div class="modal-body">
              
              

              </div>
              <div class="modal-footer d-flex justify-content-center">
                <button type="button" class="button -primary" data-bs-dismiss="modal">Continuar</button>
              </div>
            </div>
          </div>
        </div>

        {/* analyzing file... */}
        <div class="modal fade " id="loading" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="h1-b" id="exampleModalLabel">{this.state.loading ? 'Cargando': this.state.excel_error ? 'Error':'Análisis completado'}</h5>
                {
                  !this.state.loading &&
                <button type="button" class="button btn-close" data-bs-dismiss="modal" aria-label="Close"><span class="material-icons-outlined md-24">close</span></button>
                }
              </div>
              <div class="modal-body">
                {
                  this.state.loading &&
                  <Lottie
                      options={analyze}
                      width={280}
                    />
                }
                  {
                    !this.state.loading && !this.state.excel_error &&
                  <p className="body text-center"><span className="f-bold">{this.state.correct}/{this.state.total}</span> De los registros fueron aceptados, todos los registros deben ser aceptados para poder continuar</p>
                  }
                  {
                    !this.state.loading && this.state.excel_error &&
                    <p className="body text-center"><span className="f-bold"></span> Ocurrió un error al intentar analizar el archivo, revise que el documento siga el formato adecuado e intente otra vez. Si el documento se modificó después de subirlo, vuelva a subir</p>
                  }
              </div>
              {
                !this.state.loading &&
              <div class="modal-footer">
                <button type="button" class="button -secondary" data-bs-dismiss="modal">Cerrar</button>
                <button type="button" class="button -primary" data-bs-dismiss="modal">continuar</button>
              </div>
              }
              {this.state.analyzerData && this.state.correct!=this.state.total &&
                <div class="modal-footer">
                  <button type="button" class="button -secondary" data-bs-dismiss="modal" onClick={this.downloadErrorFile}>Descargar Archivo de Errores</button>
                </div>
              }
            </div>
          </div>
        </div>

        {/* Success modal */}
        <div class="modal fade " id="success" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="h1-b" id="exampleModalLabel">Registros creados exitosamente</h5>
                <button type="button" class="button btn-close" data-bs-dismiss="modal" aria-label="Close"><span class="material-icons-outlined md-24">close</span></button>
              </div>
              <div class="modal-body d-flex flex-column">
                <p className="body text-center">Todos los registros se han cargado a la plataforma.</p>
                <img className="mx-auto" src={ImgSucces} alt="imagén de un teléfono" />
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <button type="button" class="button -primary" data-bs-dismiss="modal">Continuar</button>
              </div>
            </div>
          </div>
        </div>
        {/* Success modal */}


        <SideNav
          {...this.props}
          className="d-none d-md-flex"
        />
        <div className="container-fluid px-0">
          <div className="row mx-0 d-none d-md-block">
            <div className="col-12 px-0">
              <NavBar/>
            </div>
          </div>
          <div className="row mx-0 d-md-none">
            <div className="col-12 px-0">
              <ResponsiveNavBar/>
            </div>
          </div>
          <div className="row mx-0 min-h-page">
            <div className="col-12 px-0">

              <div className="container mt-50">
                <div className="row mt-50">
                  <div className="col-12">
                    <p className="h1-b">Opt-Out</p>
                    <p className="body-r">Todos los usuarios que se cargan en esta área no recibirán más mensajes de tus campañas</p>
                  </div>
                </div>

                <div className="row mt-30">
                  {/* Formulario */}
                  {/* <div className="col-12 col-md-6">
                    <div className="card mt-20 w-100">
                      <div className="row">
                        <div className="col-12">
                          <p className="h2-r mb-30 d-flex"> Formulario
                            <a data-tip="Formulario "><span class="material-icons-outlined md-36 c-primary ml-3">info</span></a>
                            <ReactTooltip place="bottom" type="dark" effect="float"/>
                          </p>
                        </div>
                      </div>
                      
                      <div className="col-12 col-md-12 d-flex align-items-center">
                        <button className="file-upload w-100 mb-00" type="button" onClick={this.submitForm} > Ir a Formulario</button>
                        <ReactTooltip place="bottom" type="dark" effect="float"/>
                      </div>
                    </div>
                  </div> */}

                  {/* Importar Archivo */}
                  {localStorage.getItem('role') !== "4" && 
                  
                    <div className="col-12 col-lg-8">
                      <div className="card mt-20 w-100">

                        <div className="row">
                          <div className="col-12">
                            <p className="h2-r mb-30 d-flex "> 
                              Importar Archivo
                            </p>
                            <p>Adjunta el archivo de Excel con los usuarios que deseas que no reciban más mensajes de campañas, el archivo debe de respetar la plantilla.</p>
                          </div>

                          <div className="col-12">
                            <a className='button -text p-0' onClick={this.downloadEmptyTemplate} >Descargar plantilla</a>
                          </div>
                        </div>

                        <div className="row mt-30">
                        {!this.state.doc && 
                          <div className="col-12 col-md-12 d-flex align-items-center">
                            <label htmlFor="file-upload" className="file-upload mb-0">importar archivo</label>
                            {/* <input id="file-upload" type="file" name="myfile" onChange={this.loadExcelToJson}   ref={this.inputRef} accept=".xlsx"/> */}
                            <input id="file-upload" type="file" name="myfile" onChange={this.loadExcelToJson} ref={this.inputRef} accept=".xlsx"/>
                            {/* <button className="button file-upload w-100 mb-00" data-bs-toggle="modal" data-bs-target="#uploadFile">importar archivo</button> */}                          
                          </div>
                          }
                          {this.state.doc &&

                            <div className="col-12">
                              <UploadedFile
                                fileName={this.state.doc.get("doc").name}
                                action={this.reset_doc}
                              />
                            </div>

                          }
                          {this.state.doc  && !this.state.analyzer && 
                            <div className="col-12 col-md-12 mt-30">
                              <button className="button -primary" type="button" name="myfile"  data-bs-toggle="modal" onClick={this.excelAnalyzer} data-bs-target="#loading">Analizar archivo</button>
                            </div>
                          }
                          {this.state.analyzer && this.state.correct > 0  &&
                            <div className="col-12 col-md-12 mt-20">
                              <button className="button -primary w-100" type="button" name="myfile"  data-bs-toggle="modal" onClick={this.sendDoc} data-bs-target="#success">Registrar usuarios</button>
                            </div>
                          }
                          {this.state.analyzerData && this.state.correct!=this.state.total &&
                            <div className="col-12">
                              <button type="button" class="button -secondary mt-20" data-bs-dismiss="modal" onClick={this.downloadErrorFile}>Descargar Archivo de Errores</button>
                            </div>      
                          }
                        </div>
                      </div>
                    </div>
                  }

                  {/* Decarga Datos */}
                  { ( localStorage.getItem('role') === "1" || localStorage.getItem('role') === "4") && <div className="col-12 col-lg-4">
                    <div className="card mt-20 w-100 bg-secondary">
                      <div className="row">
                        <div className="col-12">
                          <p className="h2-r mb-30 d-flex c-white"> Descarga de Usuarios</p>
                          <p className="body-r c-white">Descarga un archivo con los usuarios que no recibirán mensajes de tus campañas.</p>
                        </div>
                      </div>
                      
                      <div className="col-12 col-md-12 d-flex align-items-center">
                        <button className="button -primary w-100 mb-00" type="button" onClick={this.downloadList} ><i class="fa-solid fa-download"></i> DESCARGAR registro</button>
                        <ReactTooltip place="bottom" type="dark" effect="float"/>
                      </div>
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-12 px-0">
              <Footer
                className="mt-50"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OptOutUser;