import React, { Component } from 'react';
import Lottie from 'react-lottie';
import ImgHomeEmpty from '../../img/home_empty.svg'
import ImgUsersEmpty from '../../img/users_empty.svg'
import ImgWorkspaceEmpty from '../../img/workspace_empty.svg'
import animationData from '../../img/lottie_files/Loading.json'


class EmptyPage extends Component {
  constructor(props) {
    super(props);
    this.state = { 

    }
  }

  render() {
    const loading = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      }
    }
    return (
      <div className="empty-page">
        {
          this.props.page === 'home' &&
          <>
            {this.props.auth.isAuthenticated() &&
              <>
                <img src={ImgHomeEmpty} alt="" />
                <p className="h1-b mt-20">Aún no tienes campañas</p>
                { localStorage.getItem('role') !== "5" && <a href="/new-campaign"><button className="button -primary">crear campaña</button></a>}
              </>
            }
            {!this.props.auth.isAuthenticated() &&
              <>
              <img src={ImgHomeEmpty} alt="" />
              <p className="h1-b mt-20">Inicie sesión para acceder a las campañas</p>
            </>
            }
          </>
        }

        {
          this.props.page === 'users' && 
          <>
            <img src={ImgUsersEmpty} alt="" />
            <p className="h1-b mt-20">Aún no tienes usuarios agregados</p>
            <p className="h2-r">Registra usuarios pue puedan controlar la plataforma</p>
            <a href="/new-user"><button className="button -primary mt-20">agregar usuarios</button></a>
          </>
        }

        {
          this.props.page === 'workspace' && 
          <>
            <img src={ImgWorkspaceEmpty} alt="" />
            <p className="h1-b mt-20">Aún no tienes workspaces</p>
            <p className="h2-r">Crea un nuevo workspace al que podrás asignar usuarios Para que comiencen a crear campañas</p>
            <a href="/new-campaign"><button className="button -primary mt-20">crear workspace</button></a>
          </>
        }

        {
          this.props.page === 'loading' && 
          <>
            <Lottie
              options={loading}
            />          
          </>
        }
      </div>
    );
  }
}

export default EmptyPage;