import React, { Component } from 'react';
import SideNav from '../Components/Sidenav/Sidenav'
import NavBar from '../Components/NavBar/NavBar'
import ResponsiveNavBar from '../Components/ResponsiveNavBar/ResponsiveNavBar'
import ReactTooltip from 'react-tooltip';
import Footer from '../Components/Footer/Footer'
import { Progress } from 'reactstrap';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { Doughnut,Bar } from 'react-chartjs-2';
import Lottie from 'react-lottie';
import animationData from '../img/lottie_files/Analyze.json';

const API_URL = process.env.REACT_APP_API_URL
const REPORTER_URL = process.env.REACT_APP_REPORTER
const ACCESS_TOKEN = process.env.REACT_APP_ACCESS_TOKEN
const storage_url='https://storage.cloud.google.com/afore_excel_files'

class CampaignDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {  
      campaign:{},
      successes:0,
      errors:0,
      total:0,
      interacted:0,
      registry_error:0,
      status:"",
      created:"",
      sheduled:"",
      cancelled:"",
      doughnut: {},
      stackedOptions:{
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          y: {
              stacked: true,
            },
          x: {
              stacked: true,
            },
        },
      },
      stacked:{}
    }
  }

  componentDidMount = () => {
    let params = this.props.location[0].match.params
    this.setState({id:params.id})
    axios({
      method: 'POST',
      url: API_URL + '/clip/campaign_details/outbound',
      data:{
        "campaign": params.id
      },
      headers: {"X-Api-Key": ACCESS_TOKEN}    
    })
    .then( (res) => {
      let d = {
        labels: ['Sin error', 'Error de cliente', 'Error del proveedor', 'Error del sistema', 'Pendientes', 'Error de opt-in'],
        datasets: [
          {
            label: '# of Votes',
            data: [res.data.response.recibidos, res.data.response.errores_client, res.data.response.errores_provider, res.data.response.errores_system, res.data.response.pendientes, res.data.response.mensajes_no_opt_in ? res.data.response.mensajes_no_opt_in : 0],
            backgroundColor: [
              '#354DE8',
              '#A00A94',
              '#707070',
              '#151F5C',
              '#ebdd1e',
              '#A00A0A'
            ],
          },
        ],
      }
      let s = {
        labels: ['Mensajes'],
        datasets: [
          {
            label: 'Mensajes sin interactuar',
            data: [res.data.response.recibidos-res.data.response.mensajes_interactuados],
            backgroundColor: '#354DE8',
          },
          {
            label: 'Mensajes interactuados',
            data: [res.data.response.mensajes_interactuados],
            backgroundColor: '#151F5C',
          },
        ],
      }
      this.setState({
        mensajes_no_opt_in: res.data.response.mensajes_no_opt_in ? res.data.response.mensajes_no_opt_in : 0,
        campaign:res.data.response, 
        successes:res.data.response.recibidos,
        total:res.data.response.mensajes_totales,
        total_registry:res.data.response.mensajes_totales + Math.abs(res.data.response.registry_error),
        interacted:res.data.response.mensajes_interactuados,
        registry_error: Math.abs(res.data.response.registry_error),
        doughnut:d,
        stacked:s, 
        status: res.data.response.status!="" ? res.data.response.status.toUpperCase() : "",
      })
    }).catch(error => {
      console.error('Error found in:', (error));
    });   
  }

  generate_report = () => {
    if(this.state.loading || !this.state.id)return
    this.setState({loading:true})
    console.log(REPORTER_URL, `${API_URL}/Clip/Download/CampaignDetails`, this.state.id)
    axios({
      method: 'POST',
      url: `${API_URL}/Clip/Download/CampaignDetails`,
      data:{
        campaign:this.state.id
      },
      config: { headers: { 'Content-Type': 'application/json' } }
    })
    .then( (res) => {
      console.log(res.data)
      // this.setState({
      //   file_key:`${storage_url}/${res.data.key}?authuser=0`,
      //   loading:false
      // })
      window.location.replace(`${storage_url}/${res.data.key}?authuser=0`)
      // if(res.data.error_msg.length > 0) document.getElementById("error_display").click();
    }).catch(error => {
      this.setState({loading:false,error:true})
      console.error(error)
    });
  }

  dateValid = () => {
    const d=new Date(this.state.campaign.cancelled).toLocaleDateString('es-MX',{ year: 'numeric', month: 'long', day: 'numeric', hour:'numeric',minute:'numeric' })
    if (d.toString()!="Invalid Date"){
      console.log("true")
      return true;
      
    }else{
      console.log("false")
      return false

    }
    
  }
  
  send_bulk = () => {
    console.log("entraste a cancelar");
    axios({
      method: 'POST',
      url: API_URL + '/message/whatsapp/Cancel',
      data:{
        campaign:this.state.id,
        registers : this.state.campaign.pendientes,
        requestedBy: "WebService"
      },
      headers: { 
        'X-Api-Key': ACCESS_TOKEN,
        'Content-Type': 'application/json' 
      }
    })
    .then( (res) => {
      console.log(res.data);
      document.getElementById("cancelCampaign").click();
      this.setState({loading: false});
      this.setState({redirect:true});
      
    }).catch(error => {
      console.log(error)
      document.getElementById("cancelCampaign").click();
      this.setState({loading: false});
      this.setState({redirect:true});
    });
  }

  render() {
    const analyze = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      }
    }
    return (
      <div className="container-fluid px-0 d-flex">
        {this.state.redirect &&
          <Redirect to={'/seller-admin'}></Redirect>
        }
        {/* Cancel Campaign modal */}
        <div class="modal fade " id="cancelCampaign" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="h1-b" id="exampleModalLabel">Cancelar Campaña</h5>
                <button type="button" class="button btn-close" data-bs-dismiss="modal" aria-label="Close"><span class="material-icons-outlined md-24">close</span></button>
              </div>
              <div class="modal-body">
                <p className="body text-center">Se cancelará el envío de todos los mensajes de esta campaña</p>  
              </div>
              <div class="modal-footer">
                <button type="button" class="button -text" data-bs-dismiss="modal">Regresar</button>
                <button type="button" class="button -danger" onClick={this.send_bulk}>Cancelar Campaña</button>
              </div>
            </div>
          </div>
        </div>
        <SideNav
        {...this.props}
          className="d-none d-md-flex"
        />
        <div className="container-fluid px-0">
          <div className="row mx-0 d-none d-md-block">
            <div className="col-12 px-0">
              <NavBar/>
            </div>
          </div>
          <div className="row mx-0 d-md-none">
            <div className="col-12 px-0">
              <ResponsiveNavBar/>
            </div>
          </div>
          <div className="row mx-0 min-h-page">

        {/* analyzing file... */}
        <div class="modal fade " id="loading" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="h1-b" id="exampleModalLabel">{this.state.loading ? 'Cargando': this.state.excel_error ? 'Error':'Completado'}</h5>
                {
                  !this.state.loading &&
                <button type="button" class="button btn-close" data-bs-dismiss="modal" aria-label="Close"><span class="material-icons-outlined md-24">close</span></button>
                }
              </div>
              <div class="modal-body">
                {
                  this.state.loading &&
                  <Lottie
                      options={analyze}
                      width={280}
                    />
                }
                  {
                    !this.state.loading && this.state.error &&
                    <p className="body text-center"><span className="f-bold"></span> Ocurrió un error al intentar generar el reporte</p>
                  }
              </div>
              {
                this.state.file_key &&
              <div class="modal-footer">
                <button type="button" class="button -text" data-bs-dismiss="modal">continuar</button>
              </div>
              }
              {this.state.file_key &&
                <div class="modal-footer">
                  <a href={this.state.file_key}>
                      Descargar Reporte
                  </a>
                </div>
              }
            </div>
          </div>
        </div>
        {/* analyzing file... */}
            <div className="col-12 px-0">

              {/* Content of te page */}
              <div className="container mt-50">
                <div className="row">
                  <div className="col-12">
                    <p className="h1-b">{this.state.id}</p>
                    {
                      this.state.status=="CANCELLED" &&
                      <p className="h3-b">Campaña cancelada</p>
                    }
                    {
                      this.state.status=="PROGRAMED_TO_DELIVER" &&
                      <p className="h3-b">Campaña programada para envío</p>
                    }
                    {
                      this.state.status!="PROGRAMED_TO_DELIVER" && this.state.status!="CANCELLED" &&
                      <p className="h3-b">Campaña enviada</p>
                    }
                    
                  </div>
                  <div className="col-12">
                    <p>Creado el: {new Date(this.state.campaign.created).toLocaleDateString('es-MX',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour:'numeric',minute:'numeric' })}</p>
                    <p>Lanzamiento: {new Date(this.state.campaign.scheduled).toLocaleDateString('es-MX',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour:'numeric',minute:'numeric' })}</p>
                    {this.state.campaign.cancelled!=null && this.state.campaign.cancelled!="" && this.dateValid() &&
                      <p>Cancelado: {new Date(this.state.campaign.cancelled).toLocaleDateString('es-MX',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour:'numeric',minute:'numeric' })}</p>
                    }
                    {this.state.file_key &&
                      <a href={this.state.file_key}>
                        <button 
                        className="button -primary w-25" 
                        type="button" 
                        name="myfile" 
                        >{'Descargar Reporte'}
                      </button>
                      </a>
                    }
                  
                    {!this.state.file_key && localStorage.getItem('role') !== "4"  &&                    
                        <button 
                          className="button -primary w-25" 
                          type="button" 
                          onClick={() => window.open(API_URL + '/Clip/Download/CampaignDetails' +`?campaign=${this.state.id}`, "_blank" )}
                          name="myfile" 
                          data-bs-target="#loading" 
                          id={'loading'}
                          >{this.state.loading ? 'Generando reporte...': this.state.file_key ? 'Descargar reporte' : 'Generar Reporte'}
                        </button>  
                    }
                    {this.state.campaign.pendientes>0 && this.state.status!="DELIVERED" && this.state.status!= "CANCELLED" && localStorage.getItem('role') !== "4"  && 
                        <button 
                          className="button -cancel w-25" 
                          type="button"
                          data-bs-toggle="modal" 
                          data-bs-target="#cancelCampaign">Cancelar Campaña</button>
                    }
                   
                  </div>
                </div>

                <div className="row mt-30">
                  <div className="col-12 col-lg-8">
                    <div className="card mb-20">
                      <div className="row">
                        <div className="col-12">
                          <p className="h3-r text-center">Estado de los mensajes</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="error-meaning">
                            <div className="errorblock -noerror mt-20">
                              <div className="row mx-0 mt-2">
                                <a data-tip="Estos mensajes fueron enviados de forma exitosa."><span class="material-icons-outlined md-24 c-primary">info</span></a>
                                <ReactTooltip place="bottom" type="dark" effect="float"/>
                              </div>
                              <div className="row mx-0">
                                <p className="mb-0 ml-1 body-b">Mensajes sin error</p>
                              </div>
                              <div className="row mx-0 ml-1">
                                <p className="mb-0 body-small-r">Mensajes</p>
                                <p className="mb-0 body-small-r ml-auto">{this.state.successes}</p>
                              </div>
                              <div className="row mx-0 ml-1">
                                <p className="mb-0 body-small-r">Porcentaje</p>
                                <p className="mb-0 body-small-r ml-auto">{Math.round((this.state.successes/this.state.total )*100)}%</p>
                              </div>
                            </div>

                            <div className="errorblock -registererror mt-20">
                              <div className="row mx-0 mt-2">
                                <a data-tip="Los mensajes no se han completado correctamente debido a una configuración incorrecta de la aplicación."><span class="material-icons-outlined md-24 c-primary">info</span></a>
                                <ReactTooltip place="bottom" type="dark" effect="float"/>
                              </div>
                              <div className="row mx-0">
                                <p className="mb-0 ml-1 body-b">Error de cliente</p>
                              </div>
                              <div className="row mx-0 ml-1">
                                <p className="mb-0 body-small-r">Mensajes</p>
                                <p className="mb-0 body-small-r ml-auto">{this.state.campaign.errores_client}</p>
                              </div>
                              <div className="row mx-0 ml-1">
                                <p className="mb-0 body-small-r">Porcentaje</p>
                                <p className="mb-0 body-small-r ml-auto">{Math.round(((this.state.campaign.errores_client)/this.state.total )*100)}%</p>
                              </div>
                            </div>

                            <div className="errorblock -clouderror mt-20">
                              <div className="row mx-0 mt-2">
                                <a data-tip="Los mensajes no se han completado correctamente debido a un error del proveedor de servicios de WhatsApp."><span class="material-icons-outlined md-24 c-primary">info</span></a>
                                <ReactTooltip place="bottom" type="dark" effect="float"/>
                              </div>
                              <div className="row mx-0">
                                <p className="mb-0 ml-1 body-b">Error de proveedor</p>
                              </div>
                              <div className="row mx-0 ml-1">
                                <p className="mb-0 body-small-r">Mensajes</p>
                                <p className="mb-0 body-small-r ml-auto">{this.state.campaign.errores_provider}</p>
                              </div>
                              <div className="row mx-0 ml-1">
                                <p className="mb-0 body-small-r">Porcentaje</p>
                                <p className="mb-0 body-small-r ml-auto">{Math.round((this.state.campaign.errores_provider/this.state.total) *100)}%</p>
                              </div>
                            </div>

                            <div className="errorblock -expirationerror mt-20">
                              <div className="row mx-0 mt-2">
                                <a data-tip="Los mensajes no se han completado correctamente debido a un error relacionado con el sistema de WhatsApp."><span class="material-icons-outlined md-24 c-primary">info</span></a>
                                <ReactTooltip place="bottom" type="dark" effect="float"/>
                              </div>
                              <div className="row mx-0">
                                <p className="mb-0 ml-1 body-b">Error de sistema</p>
                              </div>
                              <div className="row mx-0 ml-1">
                                <p className="mb-0 body-small-r">Mensajes</p>
                                <p className="mb-0 body-small-r ml-auto">{this.state.campaign.errores_system}</p>
                              </div>
                              <div className="row mx-0 ml-1">
                                <p className="mb-0 body-small-r">Porcentaje</p>
                                <p className="mb-0 body-small-r ml-auto">{Math.round((this.state.campaign.errores_system/this.state.total) *100)}%</p>
                              </div>
                            </div>

                            <div className="errorblock -pending mt-20">
                              <div className="row mx-0 mt-2">
                                <a data-tip="Estos mensajes se encuentran pendientes de ser enviados."><span class="material-icons-outlined md-24 c-primary">info</span></a>
                                <ReactTooltip place="bottom" type="dark" effect="float"/>
                              </div>
                              <div className="row mx-0">
                                <p className="mb-0 ml-1 body-b">Mensajes pendientes</p>
                              </div>
                              <div className="row mx-0 ml-1">
                                <p className="mb-0 body-small-r">Mensajes</p>
                                <p className="mb-0 body-small-r ml-auto">{this.state.campaign.pendientes}</p>
                              </div>
                              <div className="row mx-0 ml-1">
                                <p className="mb-0 body-small-r">Porcentaje</p>
                                <p className="mb-0 body-small-r ml-auto">{Math.round(((this.state.campaign.pendientes)/this.state.total )*100)}%</p>
                              </div>
                            </div> 

                            <div className="errorblock -optimerror mt-20">
                              <div className="row mx-0 mt-2">
                                <a data-tip="Los mensajes no se han completado debido a que no cuentan con opt-in."><span class="material-icons-outlined md-24 c-primary">info</span></a>
                                <ReactTooltip place="bottom" type="dark" effect="float"/>
                              </div>
                              <div className="row mx-0">
                                <p className="mb-0 ml-1 body-b">Error de opt-in</p>
                              </div>
                              <div className="row mx-0 ml-1">
                                <p className="mb-0 body-small-r">Mensajes</p>
                                <p className="mb-0 body-small-r ml-auto">{this.state.mensajes_no_opt_in}</p>
                              </div>
                              <div className="row mx-0 ml-1">
                                <p className="mb-0 body-small-r">Porcentaje</p>
                                <p className="mb-0 body-small-r ml-auto">{Math.round(((this.state.mensajes_no_opt_in)/this.state.total )*100)}%</p>
                              </div>
                            </div> 

                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-center mt-20">
                        <div className="col-12">
                          <Doughnut 
                            data={this.state.doughnut}
                            options={{ maintainAspectRatio: false }}
                            height={300}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="card">
                      <div className="row">
                        <div className="col-12">
                          <Progress multi>
                            <Progress bar color="noerror" value={(this.state.successes/this.state.total_registry)*100} />
                            <Progress bar color="registererror" value={(this.state.campaign.errores_client/this.state.total_registry)*100} />
                            <Progress bar color="clouderror" value={(this.state.campaign.errores_provider/this.state.total_registry)*100} />
                            <Progress bar color="expirationerror" value={(this.state.campaign.errores_system/this.state.total_registry)*100} />
                            <Progress bar color="optimerror" value={(this.state.registry_error/this.state.total_registry)*100} />
                          </Progress>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div className="col-12 col-lg-4">
                    <div className="card mb-20">
                      <div className="row">
                        <div className="col-12">
                          <p className="h3-r text-center">Estatus de mensajes</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div className="error-meaning">
                            <div className="errorblock -status -received mt-20">
                                <div className="row mx-0 mt-2">
                                  <a data-tip="Estos mensajes aún no han sido interactuados por los contactos."><span class="material-icons-outlined md-24 c-primary">info</span></a>
                                  <ReactTooltip place="bottom" type="dark" effect="float"/>
                                </div>
                                <div className="row mx-0">
                                  <p className="mb-0 ml-1 body-b">Mensajes sin interactuar</p>
                                </div>
                                <div className="row mx-0 ml-1">
                                  <p className="mb-0 body-small-r">Mensajes</p>
                                  <p className="mb-0 body-small-r ml-auto">{this.state.successes-this.state.interacted}</p>
                                </div>
                                <div className="row mx-0 ml-1">
                                  <p className="mb-0 body-small-r">Porcentaje</p>
                                  <p className="mb-0 body-small-r ml-auto">{Math.round(((this.state.successes-this.state.interacted)/this.state.total )*100)}%</p>
                                </div>
                            </div>

                            <div className="errorblock -status -interacted mt-20">
                                <div className="row mx-0 mt-2">
                                  <a data-tip="Estos mensajes han llegado a los contactos."><span class="material-icons-outlined md-24 c-primary">info</span></a>
                                  <ReactTooltip place="bottom" type="dark" effect="float"/>
                                </div>
                                <div className="row mx-0">
                                  <p className="mb-0 ml-1 body-b">Mensajes interactuados</p>
                                </div>
                                <div className="row mx-0 ml-1">
                                  <p className="mb-0 body-small-r">Mensajes</p>
                                  <p className="mb-0 body-small-r ml-auto">{this.state.interacted}</p>
                                </div>
                                <div className="row mx-0 ml-1">
                                  <p className="mb-0 body-small-r">Porcentaje</p>
                                  <p className="mb-0 body-small-r ml-auto">{Math.round((this.state.interacted/this.state.total )*100)}%</p>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-20">
                        <div className="col-12">
                          <Bar data={this.state.stacked} options={this.state.stackedOptions} height={300}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-8">
                    <div className="card mt-20">
                      <div className="row">
                        <div className="col-12">
                          <p className="h3-r text-center">Registros aceptados</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                        <Progress multi>  
                            <Progress bar color="noerror" value={((this.state.total_registry-this.state.registry_error)/this.state.total_registry )*100} />
                            <Progress bar color="optimerror" value={(this.state.registry_error/this.state.total_registry)*100} />
                          </Progress>
                        </div>
                      </div>
                      <div className="row mx-0 ml-1 mt-20">
                        <p className="mb-0 body-small-r">Registros aceptados</p>
                        <p className="mb-0 body-small-r ml-auto">{this.state.campaign.mensajes_totales}</p>
                      </div>
                      <div className="row mx-0 ml-1">
                        <p className="mb-0 body-small-r">Registros rechazados</p>
                        <p className="mb-0 body-small-r ml-auto">{this.state.registry_error}</p>
                      </div>
                      <div className="row mx-0 ml-1">
                        <p className="mb-0 body-small-r">Porcentaje</p>
                        <p className="mb-0 body-small-r ml-auto">{Math.round(((this.state.campaign.mensajes_totales)/this.state.total_registry )*100)}%</p>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              {/* Content of te page */}
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-12 px-0">
              <Footer
                className="mt-50"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CampaignDetail;