import React, { Component } from 'react';
import Logo from '../../img/logo.svg'
import {
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

class Sidenav extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }
  login = () => {
    this.props.auth.login()
  }
  logout = () => {
    this.props.auth.logout()
  }

  render() {
    return (
      <div className={`sidenav ${this.props.className}`}>
        <div className="logocontainer">
          <img src={Logo} alt="" />
        </div>
        { ( localStorage.getItem('role') !== "5" ) &&
          <a className="navelement" href='/home'>
          <div className="navlink"><span class="material-icons-outlined md-24 mr-2">home</span> Inicio</div>
        </a>}
        {( localStorage.getItem('role') === "1" || localStorage.getItem('role') === "2" || localStorage.getItem('role') === "3"  ) &&
          <a className="navelement" href='/new-campaign'>
          <div className="navlink"><span class="material-icons-outlined md-24 mr-2">campaign</span> Nueva campaña</div>
        </a>}
        {/* <a className="navelement" href='/users'>
          <div className="navlink"><span class="material-icons-outlined md-24 mr-2">group</span> Usuarios</div>
        </a>
        <a className="navelement" href='/campaign-detail'>
          <div className="navlink"><span class="material-icons-outlined md-24 mr-2">space_dashboard</span> Workspaces</div>
        </a> */}
        { ( localStorage.getItem('role') === "1" || localStorage.getItem('role') === "2" || localStorage.getItem('role') === "4" ) && 
          <a className="navelement" href='/indicators'>
            <div className="navlink"><span class="material-icons-outlined md-24 mr-2">speed</span> Indicadores</div>
          </a>
        }
        { ( localStorage.getItem('role') === "1" || localStorage.getItem('role') === "2" || localStorage.getItem('role') === "4" ) &&
          <a className="navelement" href='/analytics'>
            <div className="navlink"><span class="material-icons-outlined md-24 mr-2">analytics</span> Datos de interés</div>
          </a>
        }
        { (localStorage.getItem('role') === "1" || localStorage.getItem('role') === "3" || localStorage.getItem('role') === "4" ) && 
          <a className="navelement" href='/optout'>
            <div className="navlink"><span class="material-icons-outlined md-24 mr-2">person_off</span> Opt-Out</div>

          </a>
        }
        { ( localStorage.getItem('role') === "1" || localStorage.getItem('role') === "3" || localStorage.getItem('role') === "5" )  && 
          <a className="navelement" href='/optout-form'>
            <div className="navlink"><span class="material-icons-outlined md-24 mr-2">folder_shared</span> Formulario</div>

          </a>
        }
        { ( localStorage.getItem('role') === "1" || localStorage.getItem('role') === "3" || localStorage.getItem('role') === "5" )  && 
          <a className="navelement" href='/download-survey'>
            <div className="navlink"><span class="material-icons-outlined md-24 mr-2">download</span> Encuestas App</div>

          </a>
        }
        {this.props.auth.isAuthenticated() ?
        <a className="navelement -logout">
          <button className="navlink" onClick={this.props.auth.logout} href="#">
            <span class="material-icons-outlined md-24 mr-2">logout</span> Cerrar sesión
          </button>
        </a> :
        <a className="navelement -logout">
          <button className="navlink" onClick={this.props.auth.login} href="#">
            <span class="material-icons-outlined md-24 mr-2">login</span> Iniciar sesión
          </button>
        </a> 
        }
      </div>
    );
  }
}

export default Sidenav;
