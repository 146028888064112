import React, { Component } from 'react';
import Logo from '../../img/logo-light.svg'

class ResponsiveNavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }

  render() {
    return (
      <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container-fluid">
          <a class="navbar-brand" href="#">
            <img src={Logo} alt="" />
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="/campaign-detail"><span class="material-icons-outlined md-24 mr-2">home</span> Inicio</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/new-campaign"><span class="material-icons-outlined md-24 mr-2">campaign</span> Nueva campaña</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/users"><span class="material-icons-outlined md-24 mr-2">group</span> Usuarios</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/indicators"><span class="material-icons-outlined md-24 mr-2">speed</span> Indicadores</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/analytics"><span class="material-icons-outlined md-24 mr-2">analytics</span> Datos de interés</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/optout"><span class="material-icons-outlined md-24 mr-2">person_off</span> Opt-Out</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/optout-form"><span class="material-icons-outlined md-24 mr-2">folder_shared</span>Formulario</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/download-survey"><span class="material-icons-outlined md-24 mr-2">download</span>Encuestas App</a>
              </li>
              {/* <li class="nav-item">
                <a class="nav-link" href="/campaign-detail"><span class="material-icons-outlined md-24 mr-2">space_dashboard</span> Workspaces</a>
              </li> */}
              <li class="nav-item">
                <a class="nav-link" href="#"><span class="material-icons-outlined md-24 mr-2">logout</span> Cerrar sesión</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default ResponsiveNavBar;