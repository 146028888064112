import React, { Component } from 'react';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }

  render() {
    return (
      <footer className={`footer ${this.props.className}`}>
        <p className="mb-0 body-small-r c-white">2022. NDS Cognitive Labs. All rights reserved. All product, logos and company names are trademarks™ or registered® trademarks of their respective holders. 
Use of them does not imply any affiliation with or endorsement by them.</p>
      </footer>
    );
  }
}

export default Footer;
