import React, { Component } from 'react';

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }

  render() {
    return (
      <div className="navbar-lg">
        <div className="container d-flex">
          <p className="ml-auto username mb-0"><span class="material-icons md-24 mr-2">account_circle</span>{localStorage.getItem('user_name')}</p>
        </div>
      </div>
    );
  }
}

export default NavBar;
