import React, { Component } from 'react';

class Constructor extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }

  render() {
    return (
      <div className="constructor">
        <div className="icon">
          <img className="image" src={this.props.icon} alt="" />
        </div>
        <div className="body">
          <p className="h3-b">{this.props.title}</p>
          <p className="body">{this.props.content}</p>
          <a href={this.props.url}><button className="button -text p-0">{this.props.button}</button></a>
        </div>
      </div>
    );
  }
}

export default Constructor;