import { cardContentClasses } from '@mui/material';
import React, { Component } from 'react';
import SideNav from '../Components/Sidenav/Sidenav'
import NavBar from '../Components/NavBar/NavBar'
import ResponsiveNavBar from '../Components/ResponsiveNavBar/ResponsiveNavBar'
import { Bar } from 'react-chartjs-2';
import Footer from '../Components/Footer/Footer'
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import DatePicker from "react-datepicker";

const API_URL = process.env.REACT_APP_API_URL
const ACCESS_TOKEN = process.env.REACT_APP_ACCESS_TOKEN
const options = [
  { value: 'All', label: 'Todas' },
  { value: 'Zendesk', label: 'Formulario Zendesk' },
  { value: 'Risk', label: 'Riesgo - Prevencion de fraudes' },
  { value: 'WebService', label: 'Envío manual' }
];

class componentName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      template_filter:"",
      user_filter:"",
      group_filter:"",
      category_filter:"",
      origin_phone_filter:"",
      platform_filter:"All",

      date_filter_start:'',
      date_filter_end:'',
      date_range:'',

      phone_array :'',
      users:[],
      category:[],
      group:[],
      origin_phones_list:[],

      dataHorBar : {
        labels: ['Agente', 'Asistente'],
        datasets: [
          {
            label: ['Interacciones'],
            backgroundColor: ['#354DE8', '#151F5C'],
            borderWidth: 1,
            hoverBackgroundColor: ['rgba(53,77,232,.5)', 'rgba(21,31,92,.5)'],
            hoverBorderColor: ['#354DE8', '#151F5C'],
            data: []
          }
        ]
      },
      template : {
        labels: ["k","k","k","k","k","k","k","k","k","k","k","k","k","k","k","k","k","k","k","k"],
        datasets: [
          {
            label: ['Veces usada'],
            backgroundColor: ['#D7DBFA', '#AEB8F5', '#8694F2', '#5D71ED', '#354DE8', '#2A3EBA', '#202E8C', '#151F5C', '#0B0F2E', '#050817'],
            borderWidth: 1,
            hoverBackgroundColor: ['rgba(53,77,232,.5)', 'rgba(21,31,92,.5)'],
            hoverBorderColor: ['#354DE8', '#151F5C'],
            data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
          }
        ]
      },
      interacted : {
        labels: ['Plantilla 1', 'Plantilla 2', 'Plantilla 3', 'Plantilla 5', 'Plantilla 5', 'Plantilla 6', 'Plantilla 7', 'Plantilla 8', 'Plantilla 9', 'Plantilla 10', 'Plantilla 11', 'Plantilla 12', 'Plantilla 13', 'Plantilla 14', 'Plantilla 15', 'Plantilla 16', 'Plantilla 17', 'Plantilla 18', 'Plantilla 18', 'Plantilla 19', 'Plantilla 20'],
        datasets: [
          {
            label: ['Interacciones'],
            backgroundColor: ['#D7DBFA', '#AEB8F5', '#8694F2', '#5D71ED', '#354DE8', '#2A3EBA', '#202E8C', '#151F5C', '#0B0F2E', '#050817'],
            borderWidth: 1,
            hoverBackgroundColor: ['rgba(53,77,232,.5)', 'rgba(21,31,92,.5)'],
            hoverBorderColor: ['#354DE8', '#151F5C'],
            data: [98279, 97278, 93423, 92423, 89762, 79826, 78726, 77925, 71998, 68936, 67902, 64567, 61029, 50292, 47298, 47109, 43903, 41090, 39837, 37292, 20000]
          }
        ]
      },
      groups : {
        labels: ['Grupo 1', 'Grupo 2', 'Grupo 3', 'Grupo 5', 'Grupo 5', 'Grupo 6', 'Grupo 7', 'Grupo 8', 'Grupo 9', 'Grupo 10', 'Grupo 11', 'Grupo 12', 'Grupo 13', 'Grupo 14', 'Grupo 15', 'Grupo 16', 'Grupo 17', 'Grupo 18', 'Grupo 18', 'Grupo 19', 'Grupo 20'],
        datasets: [
          {
            label: ['Grupos enviados'],
            backgroundColor: ['#D7DBFA', '#AEB8F5', '#8694F2', '#5D71ED', '#354DE8', '#2A3EBA', '#202E8C', '#151F5C', '#0B0F2E', '#050817'],
            borderWidth: 1,
            hoverBackgroundColor: ['rgba(53,77,232,.5)', 'rgba(21,31,92,.5)'],
            hoverBorderColor: ['#354DE8', '#151F5C'],
            data: [98279, 97278, 93423, 92423, 89762, 79826, 78726, 77925, 71998, 68936, 67902, 64567, 61029, 50292, 47298, 47109, 43903, 41090, 39837, 37292, 20000]
          }
        ]
      },
      categories : {
        labels: ['Categoría 1', 'Categoría 2', 'Categoría 3', 'Categoría 5', 'Categoría 5', 'Categoría 6', 'Categoría 7', 'Categoría 8', 'Categoría 9', 'Categoría 10', 'Categoría 11', 'Categoría 12', 'Categoría 13', 'Categoría 14', 'Categoría 15', 'Categoría 16', 'Categoría 17', 'Categoría 18', 'Categoría 18', 'Categoría 19', 'Categoría 20'],
        datasets: [
          {
            label: ['Categorías enviadas'],
            backgroundColor: ['#D7DBFA', '#AEB8F5', '#8694F2', '#5D71ED', '#354DE8', '#2A3EBA', '#202E8C', '#151F5C', '#0B0F2E', '#050817'],
            borderWidth: 1,
            hoverBackgroundColor: ['rgba(53,77,232,.5)', 'rgba(21,31,92,.5)'],
            hoverBorderColor: ['#354DE8', '#151F5C'],
            data: [98279, 97278, 93423, 92423, 89762, 79826, 78726, 77925, 71998, 68936, 67902, 64567, 61029, 50292, 47298, 47109, 43903, 41090, 39837, 37292, 20000]
          }
        ]
      },
      sended : {
        labels: ['Kemmer Jayce', 'Hayes Maxie', 'McKenzie Alf', 'Hayes Griffin', 'Schuster Joelle', 'Williamson Nichole', 'Hermann Americo', 'Abshire Kian', 'Hansen Korbin', 'Gerlach Darrin', 'Gleason Zion', 'Bruen Samir', 'MacGyver Chyna', 'Lebsack Lucinda', 'Toy Flo', 'Padberg Johanna', 'Mante Clarissa', 'Hickle Elody', 'Ortiz Lelia', 'Walter Alison', 'Gleason Zion'],
        datasets: [
          {
            label: ['Mensajes enviados'],
            backgroundColor: ['#D7DBFA', '#AEB8F5', '#8694F2', '#5D71ED', '#354DE8', '#2A3EBA', '#202E8C', '#151F5C', '#0B0F2E', '#050817'],
            borderWidth: 1,
            hoverBackgroundColor: ['rgba(53,77,232,.5)', 'rgba(21,31,92,.5)'],
            hoverBorderColor: ['#354DE8', '#151F5C'],
            data: [98279, 97278, 93423, 92423, 89762, 79826, 78726, 77925, 71998, 68936, 67902, 64567, 61029, 50292, 47298, 47109, 43903, 41090, 39837, 37292, 20000]
          }
        ]
      }


    }
  }

  dateFormat = (date) => {
    if(date === "" || date === undefined){
      return "";
    }else{
      return new Date(date).toISOString();
    }
  }

  onDateChange = e => { this.setState({date_range:e, date_filter_start:e[0], date_filter_end:e[1]}) }

  onChange = e => { this.setState({ [e.target.name]: e.target.value }) }

  buttonFilter = e => {
    this.setState({ [e.target.name]: e.target.value })
    
    let template_filter = this.state.template_filter
    let user_filter = this.state.user_filter
    let date_filter_range = this.state.date_range
    let category_filter = this.state.category_filter
    let group_filter = this.state.group_filter
    let origin_phone_filter = this.state.origin_phone_filter
    let requestedBy_filter = this.state.platform_filter
    
    this.calculate_filter(template_filter, user_filter, date_filter_range, category_filter, group_filter, origin_phone_filter, requestedBy_filter)
  }

  calculate_filter = (template, user, date, category, group, origin_phone, platform_filter) => {
    if( origin_phone == "" ){
      let temp_array = this.state.phone_array;
      origin_phone = temp_array.join("-");
    }

    // Template graph
    axios({
      method: 'GET',
      url: `${API_URL}/Clip/Statistics/GetTop?attribute=template_name&date_filter_start=${this.dateFormat(date[0])}&date_filter_end=${this.dateFormat(date[1])}&user=${user}&template=${template}&group=${group}&category=${category}&origin_phones=${origin_phone}&platform=${platform_filter}`
    })
    .then ( (res) => {

      let labelsTemplates = []
      let countTemplates = []
      let data = res.data
      let aux_template = this.state.template

      data.forEach(element => {
        labelsTemplates.push(element._id)
        countTemplates.push(element.count)
      });

      aux_template.labels = labelsTemplates;
      aux_template.datasets[0].data = countTemplates

      this.setState({ template : {} })
      this.setState({template:aux_template})
    
    })
    .catch( (error) =>  {
      console.error('Error fount in:', (error))
    })

    // Category graph
    axios({
      method: 'GET',
      url: `${API_URL}/Clip/Statistics/GetTop?attribute=category&date_filter_start=${this.dateFormat(date[0])}&date_filter_end=${this.dateFormat(date[1])}&user=${user}&template=${template}&group=${group}&category=${category}&origin_phones=${origin_phone}&platform=${platform_filter}`
    })
    .then ( (res) => {

      let labelsTemplates = []
      let countTemplates = []
      let data = res.data
      let aux_template = this.state.categories

      data.forEach(element => {
        labelsTemplates.push(element._id)
        countTemplates.push(element.count)
      });

      aux_template.labels = labelsTemplates;
      aux_template.datasets[0].data = countTemplates

      this.setState({ categories : {} })
      this.setState({categories:aux_template})
    
    })
    .catch( (error) =>  {
      console.error('Error fount in:', (error))
    })

    // Group graph
    axios({
      method: 'GET',
      url: `${API_URL}/Clip/Statistics/GetTop?attribute=zendesk_group&date_filter_start=${this.dateFormat(date[0])}&date_filter_end=${this.dateFormat(date[1])}&user=${user}&template=${template}&group=${group}&category=${category}&origin_phones=${origin_phone}&platform=${platform_filter}`
    })
    .then ( (res) => {

      let labelsTemplates = []
      let countTemplates = []
      let data = res.data
      let aux_template = this.state.groups

      data.forEach(element => {
        labelsTemplates.push(element._id)
        countTemplates.push(element.count)
      });

      aux_template.labels = labelsTemplates;
      aux_template.datasets[0].data = countTemplates

      this.setState({ groups : {} })
      this.setState({groups:aux_template})
    
    })
    .catch( (error) =>  {
      console.error('Error fount in:', (error))
    })

    // Interactions graph
    axios({
      method: 'GET',
      url: `${API_URL}/Clip/GeneralIndicators?attribute=Analytics&date_start=${this.dateFormat(date[0])}&date_end=${this.dateFormat(date[1])}&user=${user}&group=${group}&template=${template}&category=${category}&origin_phones=${origin_phone}&platform=${platform_filter}`
    })
    .then ( (res) => {
      let indicators = res.data;
      let aux_template = this.state.dataHorBar
      //dataHorBar

      aux_template.datasets[0].data = [indicators.agent_interaction, indicators.clipbot_interaction]

      this.setState({ dataHorBar : {} })
      this.setState({dataHorBar:aux_template})

    
    })
    .catch( (error) =>  {
      console.error('Error fount in:', (error))
    })

    // Interactions Msj
    axios({
      method: 'GET',
      url: `${API_URL}/Clip/Statistics/InteractionMsj?attribute=&date_filter_start=${this.dateFormat(date[0])}&date_filter_end=${this.dateFormat(date[1])}&user=${user}&template=${template}&group=${group}&category=${category}&origin_phones=${origin_phone}&platform=${platform_filter}`
    })
    .then ( (res) => {
      console.log(res)
      let labelsTemplates = []
      let countTemplates = []
      let data = res.data
      let aux_template = this.state.interacted
      console.log(data)

      if( data.length != 0 )
      {
        data.forEach(element => {
          labelsTemplates.push(element._id)
          countTemplates.push(element.count)
        });

        aux_template.labels = labelsTemplates;
        aux_template.datasets[0].data = countTemplates

        this.setState({ interacted : {} })
        this.setState({interacted:aux_template})
      }else{
        this.setState({ interacted : {} })
      }
    
    })
    .catch( (error) =>  {
      console.error('Error fount in:', (error))
    })

    // Agent graph
    axios({
      method: 'GET',
      url: `${API_URL}/Clip/Statistics/GetTop?attribute=zendesk_agent_name&date_filter_start=${this.dateFormat(date[0])}&date_filter_end=${this.dateFormat(date[1])}&user=${user}&template=${template}&group=${group}&category=${category}&origin_phones=${origin_phone}&platform=${platform_filter}`
    })
    .then ( (res) => {

      console.log(res.data)

      let labelsTemplates = []
      let countTemplates = []
      let data = res.data
      let aux_template = this.state.sended

      data.forEach(element => {
        if(element._id !== "" && element._id !== null){
          labelsTemplates.push(element._id)
          countTemplates.push(element.count)
        }
      });

      aux_template.labels = labelsTemplates;
      aux_template.datasets[0].data = countTemplates

      this.setState({ sended : {} })
      this.setState({sended:aux_template})
    
    })
    .catch( (error) =>  {
      console.error('Error fount in:', (error))
    })
    
  }

  clear = () => {
    const date_start = new Date();
    const date_end = new Date();

    let date_filter_start = new Date(date_start.setDate(date_start.getDate() - 7));

    let dates_filter_range = [date_filter_start, date_end];
    this.setState({category_filter:"",user_filter:"",date_filter_start:date_filter_start, date_filter_end:date_end,date_range:dates_filter_range, group_filter:"", template_filter:"", origin_phone_filter:"", platform_filter:"All"})
    this.calculate_filter("", "", dates_filter_range, "", "", "", "All")
  }


  componentDidMount = () => {

    const date_start = new Date();
    const date_end = new Date();

    let date_filter_start = new Date(date_start.setDate(date_start.getDate() - 7));

    let dates_filter_range = [date_filter_start, date_end];

    this.setState({ date_filter_start: date_filter_start, date_filter_end: date_end, date_range:dates_filter_range});

    let phones_options = []
    let origin_phone_number = JSON.parse(localStorage.getItem("origin_phones"));
    let string_phone_array = []
    origin_phone_number.forEach(element => {
      string_phone_array.push(element.phone);
      phones_options.push(element)

    });

    this.setState({phone_array:string_phone_array, origin_phones_list:phones_options})

    // Templates
    axios({
      method: 'GET',
      url: API_URL + '/templates/outbound/sunshine?template_case=filter',
      headers: {"X-Api-Key": ACCESS_TOKEN}
    })
    .then( (res) => {
      // let template_options = [{template_name:'Selecciona un template'}].concat(res.data.response)
      
      console.log(res.data)
      this.setState({template_options:res.data.response})
    }).catch(error => {
      console.error('Error found in:', (error));
    });  

    // Campaigns data filter
    axios({
      method: 'GET',
      url: `${API_URL}/clip/campaigns/outbound?origin_phone=${string_phone_array.join("-")}`,
      headers: {"X-Api-Key": ACCESS_TOKEN}
    })
    .then( (res) => {
      let dates = []
      let users = []
      let category = []
      let group = []

      let c = res.data.response

      for (let index = 0; index < c.length; index++) {
        if(users.indexOf(c[index].user) === -1 && c[index].requestedBy !== "Risk" ){
          users.push(c[index].user)
        }
        if(category.indexOf(c[index].category) === -1  ){
          category.push(c[index].category)
        }
        if(group.indexOf(c[index].group) === -1 ){
          if( c[index].group !== null )
          {
            group.push(c[index].group)
          }
        }
        dates.push(new Date(c[index].created))
      }
      this.setState({ users, dates, category, group })

    }).catch(error => {
      console.error('Error found in:', (error));
    });
    
    // Data for indicators
    axios({
      method: 'GET',
      url: `${API_URL}/Clip/Statistics/GetTop?attribute=template_name&date_filter_start=${this.dateFormat(date_filter_start)}&date_filter_end=${this.dateFormat(date_end)}&user=&template=&group=&category=&origin_phones=${string_phone_array.join("-")}&platform=${this.state.platform_filter}`
    })
    .then ( (res) => {

      let labelsTemplates = []
      let countTemplates = []
      let data = res.data
      let aux_template = this.state.template

      data.forEach(element => {
        labelsTemplates.push(element._id)
        countTemplates.push(element.count)
      });

      aux_template.labels = labelsTemplates;
      aux_template.datasets[0].data = countTemplates

      this.setState({ template : {} })
      this.setState({template:aux_template})
    
    })
    .catch( (error) =>  {
      console.error('Error fount in:', (error))
    })

    // Data for indicators
    axios({
      method: 'GET',
      url: `${API_URL}/Clip/Statistics/GetTop?attribute=category&date_filter_start=${this.dateFormat(date_filter_start)}&date_filter_end=${this.dateFormat(date_end)}&user=&template=&group=&category=&origin_phones=${string_phone_array.join("-")}&platform=${this.state.platform_filter}`
    })
    .then ( (res) => {

      let labelsTemplates = []
      let countTemplates = []
      let data = res.data
      let aux_template = this.state.categories

      data.forEach(element => {
        labelsTemplates.push(element._id)
        countTemplates.push(element.count)
      });

      aux_template.labels = labelsTemplates;
      aux_template.datasets[0].data = countTemplates

      this.setState({ categories : {} })
      this.setState({categories:aux_template})
    
    })
    .catch( (error) =>  {
      console.error('Error fount in:', (error))
    })

    // Data for indicators
    axios({
      method: 'GET',
      url: `${API_URL}/Clip/Statistics/GetTop?attribute=zendesk_group&date_filter_start=${this.dateFormat(date_filter_start)}&date_filter_end=${this.dateFormat(date_end)}&user=&template=&group=&category=&origin_phones=${string_phone_array.join("-")}&platform=${this.state.platform_filter}`
    })
    .then ( (res) => {

      let labelsTemplates = []
      let countTemplates = []
      let data = res.data
      let aux_template = this.state.groups

      data.forEach(element => {
        labelsTemplates.push(element._id)
        countTemplates.push(element.count)
      });

      aux_template.labels = labelsTemplates;
      aux_template.datasets[0].data = countTemplates

      this.setState({ groups : {} })
      this.setState({groups:aux_template})
    
    })
    .catch( (error) =>  {
      console.error('Error fount in:', (error))
    })

    // Data for indicators
    axios({
      method: 'GET',
      url: `${API_URL}/Clip/GeneralIndicators?attribute=Analytics&date_start=${this.dateFormat(date_filter_start)}&date_end=${this.dateFormat(date_end)}&user=&group=&template=&category=&origin_phones=${string_phone_array.join("-")}&platform=${this.state.platform_filter}`
    })
    .then ( (res) => {
      let indicators = res.data;
      let aux_template = this.state.dataHorBar
      //dataHorBar

      aux_template.datasets[0].data = [indicators.agent_interaction, indicators.clipbot_interaction]

      this.setState({ dataHorBar : {} })
      this.setState({dataHorBar:aux_template})

    
    })
    .catch( (error) =>  {
      console.error('Error fount in:', (error))
    })


    // Data for indicators
    axios({
      method: 'GET',
      url: `${API_URL}/Clip/Statistics/InteractionMsj?attribute=&date_filter_start=${this.dateFormat(date_filter_start)}&date_filter_end=${this.dateFormat(date_end)}&user=&template=&group=&category=&origin_phones=${string_phone_array.join("-")}&platform=${this.state.platform_filter}`
    })
    .then ( (res) => {

      let labelsTemplates = []
      let countTemplates = []
      let data = res.data
      let aux_template = this.state.interacted
      console.log(data)

      if( data.length != 0 )
      {
        data.forEach(element => {
          labelsTemplates.push(element._id)
          countTemplates.push(element.count)
        });

        aux_template.labels = labelsTemplates;
        aux_template.datasets[0].data = countTemplates

        this.setState({ interacted : {} })
        this.setState({interacted:aux_template})
      }else{
        this.setState({ interacted : {} })
      }
    
    })
    .catch( (error) =>  {
      console.error('Error fount in:', (error))
    })


    // Data for indicators
    axios({
      method: 'GET',
      url: `${API_URL}/Clip/Statistics/GetTop?attribute=zendesk_agent_name&date_filter_start=${this.dateFormat(date_filter_start)}&date_filter_end=${this.dateFormat(date_end)}&user=&template=&group=&category=&origin_phones=${string_phone_array.join("-")}&platform=${this.state.platform_filter}`
    })
    .then ( (res) => {

      console.log(res.data)

      let labelsTemplates = []
      let countTemplates = []
      let data = res.data
      let aux_template = this.state.sended

      data.forEach(element => {
        if(element._id !== "" && element._id !== null){
          labelsTemplates.push(element._id)
          countTemplates.push(element.count)
        }
      });

      aux_template.labels = labelsTemplates;
      aux_template.datasets[0].data = countTemplates

      this.setState({ sended : {} })
      this.setState({sended:aux_template})
    
    })
    .catch( (error) =>  {
      console.error('Error fount in:', (error))
    })

    // // Campaigns data filter
    // axios({
    //   method: 'GET',
    //   url: `${API_URL}/clip/campaigns/outbound`
    // })
    // .then( (res) => {
      

    // }).catch(error => {
    //   console.error('Error found in:', (error));
    // });   
    

  }



  render() {
    return (
      <div>
        <div className="container-fluid px-0 d-flex">
          <SideNav
            {...this.props}
            className="d-none d-md-flex"
          />
          <div className="container-fluid px-0">
            <div className="row mx-0 d-none d-md-block">
              <div className="col-12 px-0">
                <NavBar/>
              </div>
            </div>
            <div className="row mx-0 d-md-none">
              <div className="col-12 px-0">
                <ResponsiveNavBar/>
              </div>
            </div>
            <div className="row mx-0 min-h-page">
              <div className="col-12 px-0">
                <div className="container mt-50">
                  <div className="row mt-50">
                    <div className="col-12">
                      <p className="h1-b">Datos de interés</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="row">
                          <div className="col-12 col-md-12 col-lg-3">
                            <label className="inputname">Buscar por fecha</label>
                            <div>
                              <div className="date-picker-wrapper">
                                      <DatePicker 
                                        onChange={this.onDateChange}
                                        className="red-border"
                                        maxDate={new Date()}
                                        name={'date'}
                                        startDate={this.state.date_filter_start}
                                        endDate={this.state.date_filter_end}
                                        selectsRange={true}
                                        />
                                      <span class="material-icons-outlined">calendar_today</span>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-lg-3">
                            <div className="input-group">
                              <select name="user_filter" id="" placeholder="Selecciona un teléfono" onChange={this.onChange} value={this.state.user_filter}>
                              <option value="" >Todas</option>
                                {this.state.users.map((element, index)=>{
                                  return(
                                    <option value={element} key={index}>{element}</option>
                                  )
                                })}
                              </select>
                              <label className="inputname">Agente</label>
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-lg-2">
                            <div className="input-group">
                              <select name="group_filter" id="" placeholder="Selecciona un grupo" onChange={this.onChange} value={this.state.group_filter}>
                                <option value="" >Todas</option>
                                {this.state.group.map((element, index)=>{
                                  return(
                                    <option value={element} key={index}>{element}</option>
                                  )
                                })}
                              </select>
                              <label className="inputname">Grupo</label>
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-lg-2">
                            <div className="input-group">
                              <select name="template_filter" id="" placeholder="Selecciona una plantilla" onChange={this.onChange} value={this.state.template_filter}>
                                <option value="" >Todas</option>
                                {this.state.template_options && this.state.template_options.length > 0 && this.state.template_options.map((template, i)=>{
                                  return(
                                    <option value={template.template_name} key={i}>{template.template_name}</option>
                                  )
                                })}
                              </select>
                              <label className="inputname">Plantilla</label>
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-lg-2">
                            <div className="input-group">
                              <select name="category_filter" id="" placeholder="Selecciona un teléfono" onChange={this.onChange} value={this.state.category_filter}>
                                <option value="" >Todas</option>
                                {this.state.category.map((element, index)=>{
                                  return(
                                    <option value={element} key={index}>{element}</option>
                                  )
                                })}
                              </select>
                              <label className="inputname">Categoría</label>
                            </div>
                          </div> 
                        </div>
                        <div className="row mt-20">
                          {/* <div className="col-12 col-md-6 col-lg-3">
                            <div className="input-group">
                              <select name="origin_phone_filter" id="" placeholder="Selecciona un teléfono" onChange={this.onChange} value={this.state.origin_phone_filter}>
                              <option value="" >Todas</option>
                                {this.state.origin_phones_list.map(item => {
                                    return (<option key={item.value} value={item.phone}>{item.value} - {item.phone}</option>);
                                })}
                              </select>
                              <label className="inputname">Teléfono Origen</label>
                            </div>
                          </div> */}

                          <div className="col-12 col-md-6 col-lg-3">
                            <div className="input-group">
                              <select name="platform_filter" id="" placeholder="Selecciona un teléfono" onChange={this.onChange} value={this.state.platform_filter}>
                                  {options.map(item => {
                                    return (<option key={item.value} value={item.value}>{item.label}</option>);
                                })}
                              </select>
                              <label className="inputname">Método de envío</label>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-2 d-flex">
                            <a className="mt-30 w-100" data-tip="Buscar">
                              <button className="button -primary d-flex justify-content-center align-items-center w-100" onClick={this.buttonFilter}><span class="material-icons-outlined md-24">search</span></button>
                            </a>
                            <ReactTooltip place="bottom" type="dark" effect="float"/>
                          </div>
                          
                          <div className="col-12 col-md-6 col-lg-2 d-flex">
                            <a className="mt-30 w-100" data-tip="Eliminar búsqueda">
                              <button className="button -danger d-flex justify-content-center align-items-center w-100" onClick={this.clear}><span class="material-icons-outlined md-24">backspace</span></button>
                            </a>
                            <ReactTooltip place="bottom" type="dark" effect="float"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-20">
                    <div className="col-12">
                      <div className="card">
                        <div className="row">
                          <div className="col-12">
                            <p className="h2-r mb-30 d-flex"><span class="material-icons-outlined md-24 mr-2 d-flex align-items-center">touch_app</span> Interacciones</p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <Bar 
                              data={this.state.dataHorBar}
                              options={{
                                indexAxis: 'y',
                                plugins:{
                                    title:{
                                      display:false, 
                                      font:{
                                        size: 12, 
                                        family: 'montserrat'
                                      }
                                    },
                                    legend: {
                                      display: false, 
                                      position: 'right'
                                    }
                                  },
                                maintainAspectRatio: false
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-20">
                    <div className="col-12">
                      <div className="card">
                        <div className="row">
                          <div className="col-12">
                            <p className="h2-r mb-30 d-flex"><span class="material-icons-outlined md-24 mr-2 d-flex align-items-center">article</span> Top Plantillas</p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <Bar 
                              data={this.state.template}
                              options={{
                                plugins:{
                                    title:{
                                      display:false, 
                                      font:{
                                        size: 12, 
                                        family: 'montserrat'
                                      }
                                    },
                                    legend: {
                                      display: false, 
                                      position: 'top'
                                    }
                                  },
                                maintainAspectRatio: false
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-20">
                    <div className="col-12">
                      <div className="card">
                        <div className="row">
                          <div className="col-12">
                            <p className="h2-r mb-30 d-flex"><span class="material-icons-outlined md-24 mr-2 d-flex align-items-center">description</span> Top plantillas interactuadas</p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <Bar 
                              data={this.state.interacted}
                              options={{
                                plugins:{
                                    title:{
                                      display:false, 
                                      font:{
                                        size: 12, 
                                        family: 'montserrat'
                                      }
                                    },
                                    legend: {
                                      display: false, 
                                      position: 'top'
                                    }
                                  },
                                maintainAspectRatio: false
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row mt-20">
                    <div className="col-12">
                      <div className="card">
                        <div className="row">
                          <div className="col-12">
                            <p className="h2-r mb-30 d-flex"><span class="material-icons-outlined md-24 mr-2 d-flex align-items-center">support_agent</span> Agentes con más mensajes enviados</p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <Bar 
                              data={this.state.sended}
                              options={{
                                plugins:{
                                    title:{
                                      display:false, 
                                      font:{
                                        size: 12, 
                                        family: 'montserrat'
                                      }
                                    },
                                    legend: {
                                      display: false, 
                                      position: 'top'
                                    }
                                  },
                                maintainAspectRatio: false
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="row mt-20">
                    <div className="col-12">
                      <div className="card">
                        <div className="row">
                          <div className="col-12">
                            <p className="h2-r mb-30 d-flex"><span class="material-icons-outlined md-24 mr-2 d-flex align-items-center">workspaces</span> Grupos de envío</p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <Bar 
                              data={this.state.groups}
                              options={{
                                plugins:{
                                    title:{
                                      display:false, 
                                      font:{
                                        size: 12, 
                                        family: 'montserrat'
                                      }
                                    },
                                    legend: {
                                      display: false, 
                                      position: 'top'
                                    }
                                  },
                                maintainAspectRatio: false
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-20">
                    <div className="col-12">
                      <div className="card">
                        <div className="row">
                          <div className="col-12">
                            <p className="h2-r mb-30 d-flex"><span class="material-icons-outlined md-24 mr-2 d-flex align-items-center">style</span> Categorías</p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <Bar 
                              data={this.state.categories}
                              options={{
                                plugins:{
                                    title:{
                                      display:false, 
                                      font:{
                                        size: 12, 
                                        family: 'montserrat'
                                      }
                                    },
                                    legend: {
                                      display: false, 
                                      position: 'top'
                                    }
                                  },
                                maintainAspectRatio: false
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mx-0">
              <div className="col-12 px-0">
                <Footer
                  className="mt-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default componentName;