import React, { Component } from 'react';

class PermissionsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <div className={`card permissions-card ${this.props.className} ${this.props.checkedIcon === '' ? '-checked': ''}`}>
        {
          this.props.permissions === 'SuperAdmin' && 
          <div className="body">
            <>
              <p className="h4-b">Super Administrator</p>
              <ul>
                <li>Puede crear y eliminar usuarios</li>
                <li>Puede crear y eliminar workspaces</li>
              </ul>
            </>
          </div>
        }

        {
          this.props.permissions === 'Admin' && 
          <div className="body">
            <>
              <p className="h4-b">Administrator</p>
              <ul>
                <li>Puede crear y eliminar usuarios</li>
                <li>Puede crear campañas de whatsapp</li>
              </ul>
            </>
          </div>
        }

        {
          this.props.permissions === 'Editor' && 
          <div className="body">
            <>
              <p className="h4-b">Editor</p>
              <ul>
                <li>Puede crear campañas de whatsapp</li>
              </ul>
            </>
          </div>
        }

        <div className="select">
          <button onClick={this.props.checkPermission} className="button -text p-0">
            <label className={`mb-0 ${this.props.checkedText}`} htmlFor="">Seleccionar</label>
            <span class={`material-icons-outlined md-48 ${this.props.checkedIcon}`}>check_circle_outline</span>
          </button>
        </div>
      </div>
    );
  }
}

export default PermissionsCard;