import React, { Component } from 'react';
import SideNav from '../Components/Sidenav/Sidenav'
import NavBar from '../Components/NavBar/NavBar'
import ResponsiveNavBar from '../Components/ResponsiveNavBar/ResponsiveNavBar'
import EmptyPage from '../Components/EmptyPage/EmptyPage'
import Constructor from '../Components/Constructor/Constructor';

import BannerUsers from '../img/Banner_users.svg'

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }

  render() {
    return (
      <div className="container-fluid px-0 d-flex">
        <SideNav
          {...this.props}
          className="d-none d-md-flex"
        />
        <div className="container-fluid px-0">
          <div className="row mx-0 d-none d-md-block">
            <div className="col-12 px-0">
              <NavBar/>
            </div>
          </div>
          <div className="row mx-0 d-md-none">
            <div className="col-12 px-0">
              <ResponsiveNavBar/>
            </div>
          </div>
          <div className="row mx-0 min-h-page">
            <div className="col-12 px-0">

              {/* When empty page */}
              {/* <div className="container">
                <div className="row">
                  <div className="col-12">
                    <EmptyPage
                      page="users"
                      />
                  </div>
                </div>
              </div>  */}
              {/* When empty page

              {/* Content of te page */}
              <div className="container mt-50">
                <div className="row mt-50">
                  <div className="col-12">
                    <p className="h1-b">Usuarios</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-6 col-lg-8">
                    <Constructor
                      title="Nuevo usuario"
                      content="Los usuarios podrán ejecutar diferentes tareas de acuerdo a los roles que se les otorguen."
                      button="AGREGAR NUEVO USUARIO"
                      url="/new-user"
                      icon= {BannerUsers}
                    />
                  </div>
                </div>

                <div className="row mt-50">
                  <div className="col-12 col-lg-8">
                    <div className="input-group">
                      <input type="search" name="name_filter"placeholder="Busca usuarios por nombre, workspace o email"/>
                      <span class="input-icon material-icons-outlined md-24">search</span>
                      <label className="inputname">Buscar</label>
                    </div>
                  </div>
                </div>

                <div className="row mt-50">
                  <div className="col-12">
                    <table>
                      <thead>
                        <tr>
                          <th>Nombre</th>
                          <th>Email</th>
                          <th>Rol</th>
                          <th>Workspace</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Evert Orn Kali Schimmel</td>
                          <td>abuckridge@gmail.com</td>
                          <td>Editor</td>
                          <td>Actinver LUCY</td>
                          <td>
                            <button className="button -icon">
                              <span class="material-icons-outlined">edit
                              </span>
                            </button>
                            <button className="button -icon -danger">
                              <span class="material-icons-outlined">delete_outline</span>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Users;